import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import useAuth from 'hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
  const {
    auth: { isLoggedIn, roles }
  } = useAuth();
  const location = useLocation();

  const isAllowed =
    isLoggedIn &&
    (allowedRoles.length === 0 ||
      roles.some(role => allowedRoles.includes(role)));

  if (!isAllowed) {
    // Set the cookie to expire in 1 day. Adjust the duration as needed.
    Cookies.set('redirectUrl', location.pathname, { expires: 1 });
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RequireAuth;
