import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import createMarkup from 'helpers/createMarkup';

const PageHeader = ({
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  col,
  children
}) => (
  <>
    <Row>
      <Col {...col}>
        {preTitle && <h6 className="text-600">{preTitle}</h6>}
        <TitleTag className="mb-0">{title}</TitleTag>
        {description && (
          <p
            className={classNames('mt-2', { 'mb-0': !children })}
            dangerouslySetInnerHTML={createMarkup(description)}
          />
        )}
        {children}
      </Col>
    </Row>
  </>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  className: PropTypes.string,
  children: PropTypes.node
};

PageHeader.defaultProps = { col: { lg: 8 }, titleTag: 'h3' };

export default PageHeader;
