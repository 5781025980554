import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form } from 'react-bootstrap';
import { CampaignContext } from 'context/Context';

const CampaignForm = () => {
  const { newCampaign, setNewCampaign, setValidated } =
    useContext(CampaignContext);

  const handleInputChange = e => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setValidated(true);
    }
    setNewCampaign({
      ...newCampaign,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row>
      <Col md={6}>
        <Form.Group>
          <Form.Label>Campaign Name</Form.Label>
          <Form.Control
            type="text"
            name="campaign_name"
            value={newCampaign.campaign_name}
            placeholder="Enter A Campaign Name"
            onChange={handleInputChange}
            required
          />

          <Form.Text className="text-muted">
            Campaign Name is required
          </Form.Text>
        </Form.Group>
      </Col>
    </Row>
  );
};

CampaignForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

export default CampaignForm;
