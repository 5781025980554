export const version = '2.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: true,
  navbarPosition: 'vertical', // available values: 'top', 'vertical'
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent' // available values: 'transparent', 'colored', 'dark'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
