import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import AuthContext from 'context/AuthProvider';

import { logout } from 'helpers/api/User';

const ProfileDropdown = () => {
  const { auth } = useContext(AuthContext);

  const handleLogout = async () => {
    await logout();
    return <Navigate to="/logout" />;
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatar} className="rounded-circle" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>
              {auth.user.first_name} {auth.user.last_name}
            </span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/profile">
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout} as={Link} to="logout">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
