import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import logo from 'assets/img/vagents/whitelogo.png';

import halfCircle from 'assets/img/illustrations/half-circle.png';

const AuthCardLayout = ({
  leftSideContent,
  middleContent,
  children,
  footer = true
}) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col md={10} lg={8} xl={7} xxl={6} className="py-3 position-relative">
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={5} className="text-white text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <Background
                      image={halfCircle}
                      className="bg-auth-card-shape"
                    />

                    <div className="z-index-1 position-relative light">
                      <img src={logo} alt="logo" width="145.5rem" />

                      {leftSideContent}
                    </div>
                  </div>

                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    {middleContent}
                  </div>

                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    {footer && (
                      <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                        <p className="fs--1 text-white">
                          To see our privacy statement{' '}
                          <Link className="text-white " to="/privacy">
                            Click Here
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  middleContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};

export default AuthCardLayout;
