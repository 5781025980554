import React from 'react';
import PropTypes from 'prop-types';
import NewCampaignLayout from './NewCampaignLayout';
import CampaignContextProvider from './CampaignContextProvider';

const NewCampaign = ({ variant, validation, progressBar }) => {
  return (
    <CampaignContextProvider>
      <NewCampaignLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </CampaignContextProvider>
  );
};

NewCampaign.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default NewCampaign;
