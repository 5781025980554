import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Components
import PageHeader from 'components/common/PageHeader';
import Audience from 'components/reports/analytics/audience/Audience';
import StatsCards from './StatsCards';

// import { audienceChart } from 'data/dashboard/analytics';
import { campaignStats } from 'data/dashboard/analytics';

// Api Calls

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <PageHeader
            title="Dashboard"
            breadCrumbItems={[
              {
                path: '/dashboards/default',
                active: true
              }
            ]}
          />
        </Col>
        <Col sm={6} className="text-end"></Col>
      </Row>

      <Row className="justify-content-center g-3 mb-3">
        <Col>
          <StatsCards />
        </Col>
      </Row>

      <Row className="justify-content-center g-3 mb-3">
        <Col>
          <Audience chartData={campaignStats} className="mb-3" />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
