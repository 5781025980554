import axiosWithCreds from '../../axios/axios';

export const viewOrgNumbers = async () => {
  try {
    const response = await axiosWithCreds.get(`numbers`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const viewOrgNumber = async number => {
  try {
    const response = await axiosWithCreds.get(`numbers/${number}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const buyOrgNumber = async (number, data) => {
  try {
    const response = await axiosWithCreds.put(`numbers/${number}`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const importNumbers = async data => {
  try {
    const response = await axiosWithCreds.put(`numbers/import`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
