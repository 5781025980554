import React, { useEffect } from 'react';
import { Card, Col, Row, Button, Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from 'components/common/Logo';
import axiosWithCreds from '../../../../axios/axios';

const AccountVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const token = location.pathname.split('/')[2];

  useEffect(() => {
    console.log(token);
    const verifyAccount = async () => {
      try {
        const response = await axiosWithCreds.get(`/user/activate/${token}`);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    verifyAccount();
  }, [token]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Title className="text-center">
              <Row>
                <Col md={12}>
                  <Logo />
                  Your Account Has Been Verified!
                </Col>
              </Row>
            </Card.Title>
            <Card.Body>
              <Row>
                <Col className="text-center" md="12">
                  Thank you for verifying your account. <br /> You may now
                  login.
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <hr />
              <Row>
                <Col className="text-center" md="12">
                  <Button variant="primary" onClick={() => navigate('/login')}>
                    Login
                  </Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountVerification;
