import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Tab, Nav, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudienceChart from './AudienceChart';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';

import { viewCampaigns } from 'helpers/api/Custom';

const TabTitle = ({ title, value, percentage, progress }) => (
  <div className="p-2 pe-4 text-start cursor-pointer">
    <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
    <h5 className="text-800">{value}</h5>
    <Flex alignItems="center">
      <FontAwesomeIcon
        icon={progress ? 'caret-up' : 'caret-down'}
        className={progress ? 'text-success' : 'text-warning'}
      />
      <h6
        className={`fs--2 mb-0 ms-2 ${
          progress ? 'text-success' : 'text-warning'
        }`}
      >
        {percentage}
      </h6>
    </Flex>
  </div>
);

const Audience = ({ chartData, className }) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchActiveCampaigns = async () => {
      const response = await viewCampaigns({ status: 'ACTIVE' });
      console.log(response);
      setCampaigns(response);
    };
    fetchActiveCampaigns();
  }, []);

  const handleCampaignChange = e => {
    const selectedCampaignId = e.target.value;
    const selectedCampaign = campaigns.find(
      campaign => campaign.campaign_id === selectedCampaignId
    );
    console.log(selectedCampaign);
  };

  return (
    <Card className={classNames(className, 'overflow-hidden')}>
      <Card.Header className="bg-light">
        <Row className="g-0 flex-between-center">
          <Col md={4} className="d-flex align-items-center">
            <Form.Select className="me-2" onChange={handleCampaignChange}>
              {campaigns.map((campaign, index) => (
                <option key={index} value={campaign.campaign_id}>
                  {campaign.campaign_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      <Tab.Container id="audience-tab" defaultActiveKey="activeCalls">
        <SimpleBarReact>
          <Card.Header className="p-0 bg-light">
            <Nav className="nav-tabs border-0 flex-nowrap chart-tab">
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="activeCalls">
                  <TabTitle
                    title="Active Calls"
                    value="3.9K"
                    progress={true}
                    percentage="62.0%"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="transfers">
                  <TabTitle
                    title="Transfers"
                    value="6.3K"
                    progress={true}
                    percentage="46.2%"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="dncRate">
                  <TabTitle
                    title="DNC Rate"
                    value="9.49%"
                    progress={false}
                    percentage="56.1%"
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="mb-0" eventKey="callDuration">
                  <TabTitle
                    title="AVG Call Duration"
                    value="4m 03s"
                    progress={false}
                    percentage="32.2%"
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
        </SimpleBarReact>

        <Card.Body>
          <Tab.Content className="p-0">
            <Tab.Pane unmountOnExit eventKey="activeCalls">
              <AudienceChart data={chartData.stats.activeCalls} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="transfers">
              <AudienceChart data={chartData.stats.transfers} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="dncRate">
              <AudienceChart data={chartData.stats.dncRate} />
            </Tab.Pane>
            <Tab.Pane unmountOnExit eventKey="callDuration">
              <AudienceChart data={chartData.stats.callDuration} />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>

      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 7 days</option>
              <option>Last 30 days</option>
              <option>Last 60 days</option>
              <option>Last 90 days</option>
            </Form.Select>
          </Col>
          <Col xs="auto"></Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

TabTitle.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percentage: PropTypes.string.isRequired,
  progress: PropTypes.bool
};

Audience.propTypes = {
  chartData: PropTypes.shape({
    stats: PropTypes.shape({
      activeCalls: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      transfers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      dncRate: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
      callDuration: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })
  }),
  className: PropTypes.string
};

export default Audience;
