import React, { useEffect, useRef, useCallback, useState } from 'react';

import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  useEdgesState,
  useNodesState
} from 'reactflow';

import PropTypes from 'prop-types';

import Toolbox from './Toolbox';

import nodeTypes from './node-types';

import './styles.css';

import 'reactflow/dist/style.css';

// TODO: This needs to change depending on the flow type (inbound/outbound)
const defaultNodes = [
  {
    id: 'START',
    type: 'root',
    deletable: false,
    draggable: true,
    dragging: false,
    position: { x: 0, y: 0 },
    data: {
      next: 'foobarbaz',
      dispositions: {
        handles: [],
        choices: []
      },
      errors: {},
      function: 'outbound',
      handles: {
        trigger: null,
        metadata: null,
        event: null,
        onSuccess: null,
        onFailure: null
      },
      inputs: {
        wait: {
          timeUnitType: 'for',
          timeInMinutes: 15
        },
        call: {
          destination: null
        },
        sms: {
          body: null
        },
        webhook: {
          url: null,
          method: null,
          headers: null,
          data: null
        }
      }
    }
  }
];

// TODO: This should be loaded from the API in the future onLoad.
const destinationChoices = ['Queue A', 'Queue B', 'Queue C', 'Queue D'];

// TODO: This should be loaded from the API in the future onLoad.
const dispositionChoices = [
  'Qualified',
  'Disqualified',
  'DNC',
  'Not Interested',
  'Callback',
  'Busy',
  'Language',
  'No Disposition'
];

const proOptions = { hideAttribution: true };

const FlowBuilder = ({ updateContext }) => {
  FlowBuilder.propTypes = {
    updateContext: PropTypes.func.isRequired
  };

  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  useEffect(() => {
    if (validateNodes(nodes)) {
      updateContext({ flow: { nodes, edges } });
    }
  }, [nodes, edges]);

  // const validateUrl = url => {
  //   const urlRegex = new RegExp(
  //     '^(https?:\\/\\/)?' + // protocol
  //       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //       '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //       '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //       '(\\#[-a-z\\d_]*)?$',
  //     'i'
  //   );

  //   return urlRegex.test(url);
  // };
  //Revised by charles 2023-12-01
  const validateUrl = url => {
    const urlRegex =
      /(?:(?<=:\/\/)|(?<=^)|(?<=[^a-z0-9]))(?=((?:[^:@]+?(?::[^@]+?)@)?(?:www\.)?(?:(?:[a-z0-9]+)\.)*?([;0-9%_/a-z-]+)(?:\.(?:[a-z0-9]{1,4}){1,2}(?=$|:|\/)|:)))\1/;
    return urlRegex.test(url);
  };

  //White line push to test env
  // const validateNodes = nodes => {
  //   for (let i = 0; i < nodes.length; i++) {
  //     const node = nodes[i];

  //     if (node.type === 'action') {
  //       if (node.data.function === 'webhook') {
  //         if (!validateUrl(node.data.inputs.webhook.url)) {
  //           return false;
  //         }
  //       }
  //     }
  //   }

  //   return true;
  // };

  //revised by charles 2023-12-01
  const validateNodes = nodes => {
    for (const node of nodes) {
      if (node.type === 'action' && node.data.function === 'webhook') {
        if (!validateUrl(node.data.inputs.webhook.url)) {
          return false;
        }
      }
    }
    return true;
  };

  const generateUUID = () => {
    let dt = new Date().getTime();

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;

        dt = Math.floor(dt / 16);

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );

    return uuid;
  };

  const generateNodeId = type => {
    return type + '-' + generateUUID();
  };

  const onEdgesChange = useCallback(
    changes => {
      setEdges(eds => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  // function onEdgeDelete(changes) {
  //   for (let i = 0; i < changes.length; i++) {
  //     const sourceNode = reactFlowInstance.getNode(changes[i].source);

  //     if (sourceNode.type === 'utility') {
  //       // If an edge from a utility node is deleted, remove the disposition
  //       if (sourceNode.data.dispositions.handles.length > 0) {
  //         // Remove the disposition from the array
  //         sourceNode.data.dispositions.handles =
  //           sourceNode.data.dispositions.handles.filter(
  //             disposition => disposition.trigger !== changes[i].target
  //           );
  //       }
  //     } else {
  //       // If an edge from a non-utility node is deleted, remove the trigger/metadata/event/onSuccess/onFailure
  //       if (changes[i].sourceHandle === 'trigger') {
  //         sourceNode.data.handles.trigger = null;
  //       }
  //       if (changes[i].sourceHandle === 'data') {
  //         sourceNode.data.handles.metadata = null;
  //       }
  //       if (changes[i].sourceHandle === 'event') {
  //         sourceNode.data.handles.event = null;
  //       }
  //       if (changes[i].sourceHandle === 'onSuccess') {
  //         sourceNode.data.handles.onSuccess = null;
  //       }
  //       if (changes[i].sourceHandle === 'onFailure') {
  //         sourceNode.data.handles.onFailure = null;
  //       }
  //     }
  //   }

  //   setEdges(eds => applyEdgeChanges(changes, eds));
  // }

  //revised by charles 2023-12-01
  function onEdgeDelete(changes) {
    changes.forEach(change => {
      const sourceNode = reactFlowInstance.getNode(change.source);

      if (sourceNode.type === 'utility') {
        // Remove the disposition for utility nodes
        sourceNode.data.dispositions.handles =
          sourceNode.data.dispositions.handles.filter(
            disposition => disposition.trigger !== change.target
          );
      } else {
        // Simplify the clearing of handles for non-utility nodes
        const clearableHandles = [
          'trigger',
          'metadata',
          'event',
          'onSuccess',
          'onFailure'
        ];
        if (clearableHandles.includes(change.sourceHandle)) {
          sourceNode.data.handles[change.sourceHandle] = null;
        }
      }
    });

    setEdges(eds => applyEdgeChanges(changes, eds));
  }

  // const isValidConnection = (source, target, sourceHandle, targetHandle) => {
  //   // Various rules for valid connections
  //   const sourceNode = reactFlowInstance.getNode(source);
  //   const targetNode = reactFlowInstance.getNode(target);
  //   const connectedEdges = reactFlowInstance.getEdges(sourceNode);

  //   console.log('targetHandle', targetHandle);

  //   const sourceTriggerEdges = connectedEdges.filter(
  //     edge => edge.sourceHandle === sourceHandle && edge.source === source
  //   );

  //   if (sourceTriggerEdges.length > 0) {
  //     return false;
  //   }

  //   if (sourceNode.id === targetNode.id) {
  //     return false;
  //   }

  //   return true;
  // };

  //revised by charles 2023-12-01
  const isValidConnection = (source, target, sourceHandle) => {
    // Avoid connections to the same node
    if (source === target) return false;

    // Get connected edges and check for existing connections from the same source handle
    const connectedEdges = reactFlowInstance.getEdges(source);
    const hasExistingConnection = connectedEdges.some(
      edge => edge.source === source && edge.sourceHandle === sourceHandle
    );

    return !hasExistingConnection;
  };

  const onConnect = useCallback(
    params => {
      if (
        isValidConnection(
          params.source,
          params.target,
          params.sourceHandle,
          params.targetHandle
        )
      ) {
        const sourceNode = reactFlowInstance.getNode(params.source);
        const targetNode = reactFlowInstance.getNode(params.target);

        // Set the trigger, metadata, event, onSuccess, onFailure handles
        if (
          params.sourceHandle === 'trigger' ||
          (sourceNode.type === 'action' &&
            params.sourceHandle === 'disposition')
        ) {
          sourceNode.data.handles.trigger = targetNode.id;
        }
        if (params.sourceHandle === 'data') {
          sourceNode.data.handles.metadata = targetNode.id;
        }
        if (params.sourceHandle === 'event') {
          sourceNode.data.handles.event = targetNode.id;
        }
        if (params.sourceHandle === 'onSuccess') {
          sourceNode.data.handles.onSuccess = targetNode.id;
        }
        if (params.sourceHandle === 'onFailure') {
          sourceNode.data.handles.onFailure = targetNode.id;
        }

        if (sourceNode.type === 'utility') {
          const dispositionExists = sourceNode.data.dispositions.handles.find(
            disposition => disposition.disposition === params.sourceHandle
          );

          if (dispositionExists) {
            // if disposition exists, update the trigger
            dispositionExists.trigger = targetNode.id;
          } else {
            // if disposition does not exist, add it to the array
            sourceNode.data.dispositions.handles.push({
              disposition: params.sourceHandle,
              trigger: targetNode.id
            });
          }
        }

        setEdges(eds => addEdge({ ...params, type: 'smoothstep' }, eds));
      }
    },
    [reactFlowInstance]
  );

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      const jsonData = JSON.parse(
        event.dataTransfer.getData('application/reactflow')
      );

      if (typeof jsonData === 'undefined' || !jsonData) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });

      const node = {
        data: {
          dispositions: {
            handles: [],
            choices: dispositionChoices
          },
          errors: {},
          function: jsonData.function,
          handles: {
            trigger: null,
            metadata: null,
            event: null,
            onSuccess: null,
            onFailure: null
          },
          inputs: {
            wait: {
              timeUnitType: 'for',
              timeInMinutes: 15
            },
            call: {
              destination: destinationChoices[0] ? destinationChoices[0] : null,
              choices: destinationChoices
            },
            sms: {
              body: null
            },
            webhook: {
              url: null,
              method: null,
              headers: null,
              data: null
            }
          }
        },
        deletable: true,
        draggable: true,
        dragging: false,

        id: `${generateNodeId(jsonData.type)}`,
        type: jsonData.type,
        position: position
      };

      setNodes(nds => nds.concat(node));
    },
    [reactFlowInstance]
  );

  return (
    <div className="reactflow-container" style={{ height: '700px' }}>
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            proOptions={proOptions}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onEdgesDelete={onEdgeDelete}
            fitView
          >
            <Background style={{ background: 'var(--falcon-card-bg)' }} />
            <Controls />
          </ReactFlow>
        </div>
        <Toolbox />
      </ReactFlowProvider>
    </div>
  );
};

export default FlowBuilder;
