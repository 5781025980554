import React, { useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import freeEmailDomains from 'free-email-domains';
import { toast } from 'react-toastify';
import { createOrganization } from 'helpers/api/Organization';
import { useNavigate } from 'react-router-dom';

// yup.addMethod(yup.string, 'notOneOfDomains', function (message) {
//   return this.test('domain', message, function (value) {
//     const domain = value?.split('@')[1];
//     const isInvalid = freeEmailDomains.includes(domain);
//     return !isInvalid;
//   });
// });

const schema = yup.object().shape({
  name: yup.string().required('Organization name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  address: yup.string().required('Address is required'),
  address_line_2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip_code: yup.string().required('Zip code is required'),
  phone_number: yup.string().required('Phone number is required'),
  ein: yup.string().required('Employer Identification Number is required')
});

const errorMsgs = {
  ORG_EMAIL_IN_USE: 'Organization email is already in use',
  ORG_PHONE_IN_USE: 'Organization phone number is already in use',
  ORG_EIN_IN_USE: 'Organization EIN is already in use',
  ORG_EMAIL_AND_PHONE_AND_EIN_IN_USE:
    'Organization email, phone number, and EIN are already in use',
  ORG_EMAIL_AND_PHONE_IN_USE:
    'Organization email and phone number are already in use',
  ORG_EMAIL_AND_EIN_IN_USE: 'Organization email and EIN are already in use',
  ORG_PHONE_AND_EIN_IN_USE:
    'Organization phone number and EIN are already in use'
};

const OrgRegForm = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleErrorMsg = error => {
    switch (error) {
      case 'ORG_EMAIL_IN_USE':
        setErrorMsg(errorMsgs.ORG_EMAIL_IN_USE);
        break;
      case 'ORG_PHONE_IN_USE':
        setErrorMsg(errorMsgs.ORG_PHONE_IN_USE);
        break;
      case 'ORG_EIN_IN_USE':
        setErrorMsg(errorMsgs.ORG_EIN_IN_USE);
        break;
      case 'ORG_EMAIL_AND_PHONE_AND_EIN_IN_USE':
        setErrorMsg(errorMsgs.ORG_EMAIL_AND_PHONE_AND_EIN_IN_USE);
        break;
      case 'ORG_EMAIL_AND_PHONE_IN_USE':
        setErrorMsg(errorMsgs.ORG_EMAIL_AND_PHONE_IN_USE);
        break;
      case 'ORG_EMAIL_AND_EIN_IN_USE':
        setErrorMsg(errorMsgs.ORG_EMAIL_AND_EIN_IN_USE);
        break;
      case 'ORG_PHONE_AND_EIN_IN_USE':
        setErrorMsg(errorMsgs.ORG_PHONE_AND_EIN_IN_USE);
        break;
      default:
        setErrorMsg('Error registering organization');
    }
  };

  const onSubmit = async data => {
    try {
      const response = await createOrganization(data);
      if (response.status === 200) {
        console.log(response.status);
        navigate('/payment');
      } else if (response.status === 400) {
        console.error(response.data.error);
        handleErrorMsg(response.data.error);
        toast.error(errorMsg, {
          autoClose: 5000,
          role: 'alert'
        });
      }
    } catch (error) {
      console.error(error);
      setErrorMsg('Error registering organization');
    }
  };

  return (
    <Row className="justify-content-md-center mt-5">
      <Col xs={12} md={6}>
        <Card className="p-4">
          <h1>Register Organization</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter organization name"
                {...register('name')}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid" aria-live="polite">
                {errors.name?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Organization Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter organization email"
                {...register('email')}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid" aria-live="polite">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="address" className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                {...register('address')}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid" aria-live="polite">
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="address_line_2" className="mb-3">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 2"
                {...register('address_line_2')}
                isInvalid={!!errors.address_line_2}
              />
              <Form.Control.Feedback type="invalid" aria-live="polite">
                {errors.address_line_2?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="g-2 mb-3">
              <Col sm={4}>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    {...register('city')}
                    isInvalid={!!errors.city}
                  />
                  <Form.Control.Feedback type="invalid" aria-live="polite">
                    {errors.city?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter state"
                    {...register('state')}
                    isInvalid={!!errors.state}
                  />
                  <Form.Control.Feedback type="invalid" aria-live="polite">
                    {errors.state?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="zip_code">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zip code"
                    {...register('zip_code')}
                    isInvalid={!!errors.zip_code}
                  />
                  <Form.Control.Feedback type="invalid" aria-live="polite">
                    {errors.zip_code?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="g-2 mb-3">
              <Col sm={6}>
                <Form.Group controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    {...register('phone_number')}
                    isInvalid={!!errors.phone_number}
                  />
                  <Form.Control.Feedback type="invalid" aria-live="polite">
                    {errors.phone_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="ein">
                  <Form.Label>EIN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter EIN"
                    {...register('ein')}
                    isInvalid={!!errors.ein}
                  />
                  <Form.Control.Feedback type="invalid" aria-live="polite">
                    {errors.ein?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" variant="primary" className="mt-3">
              Register
            </Button>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default OrgRegForm;
