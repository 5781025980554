import React from 'react';
import ConfirmMailContent from 'components/authentication/ConfirmMailContent';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ConfirmMail = () => (
  <AuthCardLayout
    leftSideContent={
      <div className="mt-3 mb-4 mt-md-4 mb-md-5 dark">
        <div className="text-dark fw-bold">Account Created!</div>
      </div>
    }
  >
    <div className="text-center">
      <ConfirmMailContent layout="card" titleTag="h3" />
    </div>
  </AuthCardLayout>
);

export default ConfirmMail;
