import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  Tabs,
  Tab,
  Container,
  Breadcrumb
} from 'react-bootstrap';

import PageHeader from 'components/common/PageHeader';

// import { leadDialog } from 'data/leadDialog';

const { viewOrgLead } = require('helpers/api/Org-Leads');
import { viewCampaign } from 'helpers/api/Campaign';

const LeadDetails = () => {
  const [lead, setLead] = useState({
    first_name: '',
    last_name: ''
  });
  const [campaign, setCampaign] = useState({});
  const [campaignId, setCampaignId] = useState('');
  const [edit, setEdit] = useState(true);

  const location = useLocation();

  const lead_id = location.pathname.split('/').pop();

  const toggleEdit = () => setEdit(!edit);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const response = await viewOrgLead(lead_id);
        if (response.status === 200) {
          setLead(response.data);
          setCampaignId(response.data.campaign_id);
        } else {
          console.error('Failed to fetch lead');
        }
      } catch (error) {
        console.error('Error fetching lead:', error);
      }
    };

    const fetchCampaign = async () => {
      try {
        const response = await viewCampaign(campaignId);
        if (response.status === 200) {
          setCampaign(response.data);
        } else {
          console.error('Failed to fetch campaign');
        }
      } catch (error) {
        console.error('Error fetching campaign:', error);
      }
    };

    fetchLead();
    fetchCampaign();
    console.log('Campaign:', campaign);
    console.log('Lead:', lead);
  }, [campaignId, lead_id]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <PageHeader
            title="Lead Details"
            breadCrumbItems={[
              {
                path: '/leads/details',
                active: true,
                label: 'Leads'
              }
            ]}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <Breadcrumb>
            <Breadcrumb.Item href="/leads">Leads</Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="g-2">
        <Card>
          <Card.Body>
            <Tabs
              defaultActiveKey="details"
              id="lead-details"
              className="mb-3"
              fill
            >
              <Tab eventKey="details" title="Details">
                {edit ? (
                  <Container>
                    <Form>
                      <Row>
                        <Col md={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="leadFirstName"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={lead.first_name}
                              onChange={e =>
                                setLead({ ...lead, first_name: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="leadLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lead.last_name}
                              onChange={e =>
                                setLead({ ...lead, last_name: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="leadCampaign">
                            <Form.Label>Campaign Name</Form.Label>
                            <Form.Control
                              className="form-control-plaintext text-dark"
                              type="text"
                              placeholder="Campaign"
                              readOnly
                              plaintext
                              value={
                                campaign.campaign_name
                                  ? campaign.campaign_name
                                  : 'No Campaign'
                              }
                              onChange={e =>
                                setCampaign({
                                  ...campaign,
                                  campaign_name: e.target.value
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="leadEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              value={lead.email ? lead.email : 'No Email Found'}
                              onChange={e =>
                                setLead({ ...lead, email: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                ) : (
                  <Container>No Edit</Container>
                )}
              </Tab>
              <Tab eventKey="history" title="History"></Tab>
              <Tab eventKey="notes" title="Notes"></Tab>
            </Tabs>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              onClick={toggleEdit}
              className="me-2"
            >
              {edit ? 'Save' : 'Edit'}
            </Button>
          </Card.Footer>
        </Card>
      </Row>
    </>
  );
};

export default LeadDetails;
