import axiosWithCreds from '../../axios/axios';

export const createFlow = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.post(
      `campaign/${campaignId}/flow`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getFlow = async campaignId => {
  try {
    const response = await axiosWithCreds.get(`campaign/${campaignId}/flow`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateFlow = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.post(
      `campaign/${campaignId}/flow`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
