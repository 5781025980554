import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { createWebhook } from 'helpers/api/Webhook';
const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    url: yup.string().url('Must be a valid URL').required('URL is required'),
    description: yup.string(),
    when: yup.string().required('Trigger is required'),
    api_key: yup.string().required('API Key is required')
  })
  .required();

const WebhookModal = ({ show, onHide, webhook }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: webhook || {
      name: '',
      url: '',
      when: '',
      api_key: '',
      description: ''
    }
  });

  const watchedFields = watch();

  const onSubmit = async data => {
    try {
      const response = await createWebhook(data);
      if (response.status === 200) {
        reset();
        onHide();
      } else {
        console.error('Failed to create webhook');
      }
    } catch (error) {
      console.error('Error creating webhook:', error);
    }
  };

  const renderValidationStatus = field => {
    return errors[field] ? (
      <span className="text-danger">
        <FontAwesomeIcon icon={faTimes} />
      </span>
    ) : (
      <span className="text-success">
        <FontAwesomeIcon icon={faCheck} />
      </span>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {webhook ? 'Edit Webhook' : 'Create New Webhook'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col>
                    <h6>Webhook Details</h6>
                    <ul className="list-unstyled">
                      <li>
                        Name:{' '}
                        {watchedFields.name
                          ? renderValidationStatus('name')
                          : 'Pending'}
                      </li>
                      <li>
                        URL:{' '}
                        {watchedFields.url
                          ? renderValidationStatus('url')
                          : 'Pending'}
                      </li>
                      <li>
                        API Key:{' '}
                        {watchedFields.api_key
                          ? renderValidationStatus('api_key')
                          : 'Pending'}
                      </li>
                      <li>
                        When:{' '}
                        {watchedFields.when
                          ? renderValidationStatus('when')
                          : 'Pending'}
                      </li>
                      <li>
                        Description:{' '}
                        {watchedFields.description
                          ? renderValidationStatus('description')
                          : 'None'}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={9}>
            <Card className="mb-3">
              <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {/* Your form fields with react-hook-form's register */}
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      {...register('name')}
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name.message}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicURL">
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter URL"
                      {...register('url')}
                    />
                    {errors.url && (
                      <p className="text-danger">{errors.url.message}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicAPIKey">
                    <Form.Label>API Key</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter API Key"
                      {...register('api_key')}
                    />
                    {errors.api_key && (
                      <p className="text-danger">{errors.api_key.message}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicWhen">
                    <Form.Label>Trigger</Form.Label>
                    <Form.Control as="select" {...register('when')}>
                      <option value="">Select a trigger</option>
                      <option value="LEAD_POSTED">Lead Posted</option>
                      <option value="DNC">Do Not Call</option>
                      <option value="QUALIFIED">Qualified</option>
                    </Form.Control>
                    {errors.when && (
                      <p className="text-danger">{errors.when.message}</p>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      {...register('description')}
                    />
                    {errors.description && (
                      <p className="text-danger">
                        {errors.description.message}
                      </p>
                    )}
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      {webhook ? 'Update' : 'Create '}
                    </Button>
                  </Modal.Footer>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

WebhookModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  webhook: PropTypes.object
};

export default WebhookModal;
