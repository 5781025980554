import React, { useEffect, useState } from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row } from 'react-bootstrap';
import Associations from 'components/pages/asscociations/Associations';
import avatar from 'assets/img/team/avatar.png';
import coverSrc from 'assets/img/vagents/vagent-bg-banner-2.jpg';
import { getUser } from 'helpers/api/User';
import { viewOrganization } from 'helpers/api/Organization';

const Profile = () => {
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    id: '',
    dob: ''
  });

  // Fix user roles and clean up profile cards
  const [org, setOrg] = useState({});
  const [role, setRole] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUser();
      const org = await viewOrganization();
      setProfile(result);
      setRole(result.role);
      setOrg(org);
      console.log(result);
      console.log(org);
    };
    fetchData();
  }, []);

  const { first_name, last_name, email, id, dob } = profile;

  return (
    <>
      <ProfileBanner avatar={avatar} coverSrc={coverSrc} title={role} />
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <ProfileIntro
            first_name={first_name}
            last_name={last_name}
            email={email}
            id={id}
            dob={dob}
            userRole={role}
          />
          <Associations
            company_name={org.company_name}
            company_email={org.company_email}
            org_id={org.org_id}
            phone_number={org.phone_number}
            address={org.address}
            address_line2={org.address_line2}
            city={org.city}
            state={org.state}
            zip_code={org.zip_code}
            seats={org.seats}
          />
        </Col>
      </Row>
    </>
  );
};

export default Profile;
