import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import Divider from 'components/common/Divider';

import { registerUser } from 'helpers/api/User';

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  isAccepted: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and privacy policy')
});

const RegistrationForm = ({ hasLabel }) => {
  // Form State
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(schema)
  });

  // Navigation
  const navigate = useNavigate();

  const onSubmit = async data => {
    const { first_name, last_name, email, password } = data;

    try {
      const response = await registerUser({
        first_name,
        last_name,
        email,
        password
      });

      if (response.status === 200) {
        navigate('/confirm-email', { state: { email: email } });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>First Name</Form.Label>}
          <Form.Control
            type="text"
            placeholder="Enter first name"
            name="first_name"
            {...register('first_name')}
            isInvalid={errors && errors.first_name}
          />
          {errors && errors.first_name && (
            <Form.Control.Feedback type="invalid">
              {errors.first_name.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Last Name</Form.Label>}
          <Form.Control
            type="text"
            placeholder="Enter last name"
            name="last_name"
            {...register('last_name')}
            isInvalid={errors && errors.last_name}
          />
          {errors && errors.last_name && (
            <Form.Control.Feedback type="invalid">
              {errors.last_name.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          type="email"
          placeholder="Enter email"
          name="email"
          {...register('email')}
          isInvalid={errors && errors.email}
        />
        {errors && errors.email && (
          <Form.Control.Feedback type="invalid">
            {errors.email.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            {...register('password')}
            isInvalid={errors && errors.password}
          />
          {errors && errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            {...register('confirmPassword')}
            isInvalid={errors && errors.confirmPassword}
          />
          {errors && errors.confirmPassword && (
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            {...register('isAccepted')}
            isInvalid={errors && errors.isAccepted}
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button className="w-100" type="submit" disabled={!formState.isValid}>
          Register
        </Button>
      </Form.Group>
      <Divider></Divider>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
