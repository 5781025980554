import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import AppContext from 'context/Context';
import AppRoutes from 'routes';
import { CloseButton } from 'components/common/Toast';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
// import SettingsPanel from 'components/settings-panel/SettingsPanel';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'reactflow/dist/style.css';
import { AuthProvider } from 'context/AuthProvider';
import { SubscriptionProvider } from 'context/SubscriptionContext';

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    const browserClasses = [];

    if (/Windows/.test(userAgent)) {
      browserClasses.push('windows');
    }
    if (/Chrome/.test(userAgent)) {
      browserClasses.push('chrome');
    }
    if (/Firefox/.test(userAgent)) {
      browserClasses.push('firefox');
    }
    if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      browserClasses.push('safari');
    }

    return browserClasses;
  };

  useEffect(() => {
    const browserClasses = detectBrowser();
    browserClasses.forEach(cls => HTMLClassList.add(cls));
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthProvider>
        <SubscriptionProvider>
          <AppRoutes />
        </SubscriptionProvider>
        {/* <SettingsToggle /> */}
        {/* <SettingsPanel /> */}
        <ToastContainer
          closeButton={CloseButton}
          icon={false}
          position={toast.POSITION.BOTTOM_LEFT}
        />
      </AuthProvider>
    </Router>
  );
};

export default App;
