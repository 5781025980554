import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const CampaignTableHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter
}) => {
  const navigate = useNavigate();

  const handleNewCampaign = () => {
    navigate('/campaign/new');
  };

  return (
    <Row className="flex-between-center flex-column flex-sm-row">
      <Col sm="auto" className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          className="ms-2"
          placeholder="Search Campaigns"
          data-testid="search-campaigns"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>

      <Col sm="auto" className="">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="campaign-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              className="mx-2"
              onClick={handleNewCampaign}
            >
              <span className="ms-1">New</span>
            </IconButton>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="ms-1">Export</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

CampaignTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default CampaignTableHeader;
