import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';

import CreatePromptModal from './CreatePromptModal';

const PromptTableHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Row className="flex-between-center flex-column flex-sm-row">
        <Col sm="auto" lg={3} className="d-flex align-items-center pe-0">
          <AdvanceTableSearchBox
            className="ms-2"
            placeholder="Search Prompts"
            data-testid="search-prompts"
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>

        <Col sm="auto" className="">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="campaign-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="mx-2"
                onClick={handleShowModal}
              >
                Add Prompt
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
      <CreatePromptModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

PromptTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refreshPrompts: PropTypes.func,
  show: PropTypes.bool
};

export default PromptTableHeader;
