import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CampaignContext } from 'context/Context';

const CampaignContextProvider = ({ children }) => {
  const [newCampaign, setNewCampaign] = useState({
    campaign_name: '',
    running_hours: {},
    flow: {}
  });
  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState({});
  const [step, setStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  const value = {
    newCampaign,
    setNewCampaign,
    step,
    setStep,
    leads,
    setLeads,
    lead,
    setLead,
    validated,
    setValidated,
    error,
    setError
  };
  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  );
};

CampaignContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CampaignContextProvider;
