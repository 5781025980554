import React from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProfileIntro = ({ first_name, last_name, email, id, dob, userRole }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">Personal Info</h5>
      </Card.Header>

      <Card.Body className="text-justify text-1000">
        <Form>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={first_name}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={last_name}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>User Id</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Id"
                  value={id}
                  readOnly
                  plaintext
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Date of Birth"
                  value={dob ? dob : ''}
                  readOnly
                  plaintext
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-2">
                <Form.Label>User Role</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User Role"
                  value={userRole}
                  readOnly
                  plaintext
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

ProfileIntro.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  dob: PropTypes.string,
  userRole: PropTypes.string
};

export default ProfileIntro;
