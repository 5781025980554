import React from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconItem from '../../common/icon/IconItem';
import CardDropdown from '../../common/CardDropdown';
// import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import CountUp from 'react-countup';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
// import BasicECharts from 'components/common/BasicEChart';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

// const getOptions = data => ({
//   tooltip: {
//     trigger: 'axis',
//     formatter: '<strong>{b0}</strong> : {c0}'
//   },
//   xAxis: {
//     data: ['Week 4', 'Week 5', 'week 6', 'week 7']
//   },
//   series: [
//     {
//       type: 'line',
//       data,
//       smooth: true,
//       lineStyle: {
//         color: getColor('success'),
//         width: 3
//       },

//       areaStyle: {
//         color: {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             {
//               offset: 0,
//               color: rgbaColor(getColor('success'), 0.25)
//             },
//             {
//               offset: 1,
//               color: rgbaColor(getColor('success'), 0)
//             }
//           ]
//         }
//       }
//     }
//   ]
// });

const SMSStatsCard = () => {
  return (
    <>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mb-3 position-static"
          >
            <IconItem
              tag="div"
              icon="comment-sms"
              color="success"
              bg="soft-success"
              size="sm"
              iconClass="fs--2"
              className="me-2"
            />

            <h6 className="mb-0 flex-1">SMS Sent</h6>
            <div>
              <CardDropdown href="reports/sms" text="SMS Report" />
            </div>
          </Flex>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0"
        >
          <div>
            <h2 className="fw-normal text-700 mb-1 lh-1">
              <CountUp
                start={0}
                end={Math.floor(Math.random() * (100000 - 1000 + 1)) + 10000}
                duration={6}
                delay={0}
                separator=","
                decimals={0}
                decimal=","
              />
            </h2>
            <Badge pill bg="200" className="text-success fs--2"></Badge>
            <span className="fs--2 mb-0 mt-0 text-nowrap"></span>
          </div>
          <div className="ps-0 mt-n4">
            {/* <Col xs={12} className="px-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(data)}
                style={{ width: '5.5rem', height: 45 }}
              />
            </Col> */}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

SMSStatsCard.propTypes = {
  data: PropTypes.array
};

export default SMSStatsCard;
