import axiosWithCreds from '../../axios/axios';
export const createOrganization = async data => {
  try {
    const response = await axiosWithCreds.post(`/org`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      console.error(response);
      return response;
    }
  } catch (error) {
    return error.response;
  }
};

export const viewOrganization = async () => {
  try {
    const response = await axiosWithCreds.get(`org`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getOrgStatistics = async () => {
  try {
    const response = await axiosWithCreds.get(`org/statistics`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const createOrgInvitation = async data => {
  try {
    const response = await axiosWithCreds.post(`org/invite`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const acceptOrgInvitation = async data => {
  try {
    const response = await axiosWithCreds.post(`org/accept-invite`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
