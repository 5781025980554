import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Completion = () => {
  const navigate = useNavigate();

  return (
    <Row className="justify-content-center">
      <Col md={6}>
        <Card className="text-center">
          <Card.Body>
            <Card.Title className="mb-4">Payment Successful</Card.Title>
            <Button
              variant="primary"
              className="w-100"
              onClick={() => navigate('/dashboard')}
            >
              Go to Dashboard
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Completion;
