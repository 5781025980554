import axiosWithCreds from '../../axios/axios';
export const viewCampaignLeads = async campaignId => {
  try {
    const response = await axiosWithCreds.get(`campaign/${campaignId}/leads`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewCampaignLead = async (campaignId, campaignLeadId) => {
  try {
    const response = await axiosWithCreds.get(
      `campaign/${campaignId}/leads/${campaignLeadId}`,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const createCampaignLead = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.post(
      `campaign/${campaignId}/leads`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const uploadCampaignLeads = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.put(
      `campaign/${campaignId}/leads`,
      JSON.stringify(data),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
