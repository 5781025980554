import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconItem from '../../common/icon/IconItem';
// import CardDropdown from '../../common/CardDropdown';
// import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
// import BasicECharts from 'components/common/BasicEChart';

import { viewCampaigns } from 'helpers/api//Custom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

// const getOptions = data => ({
//   tooltip: {
//     trigger: 'axis',
//     formatter: '<strong>{b0}</strong> : {c0}'
//   },
//   xAxis: {
//     data: ['Week 4', 'Week 5', 'week 6', 'week 7']
//   },
//   series: [
//     {
//       type: 'line',
//       data,
//       smooth: true,
//       lineStyle: {
//         color: getColor('info'),
//         width: 3
//       },

//       areaStyle: {
//         color: {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             {
//               offset: 0,
//               color: rgbaColor(getColor('info'), 0.25)
//             },
//             {
//               offset: 1,
//               color: rgbaColor(getColor('info'), 0)
//             }
//           ]
//         }
//       }
//     }
//   ]
// });

const CampaignStatsCard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await viewCampaigns(
        {
          status: 'ACTIVE'
        },
        {
          limit: 100
        }
      );

      console.log(response);
      setData(response);
      console.log(data);
    };

    fetchData();
    console.log(data);
  }, []);

  const filterActiveCampaigns = () => {
    const activeCampaigns = data.filter(
      campaign => campaign.status === 'ACTIVE'
    );

    return activeCampaigns;
  };

  return (
    <>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mb-3 position-static"
          >
            <IconItem
              tag="div"
              icon="bolt"
              color="info"
              bg="soft-info"
              size="sm"
              iconClass="fs--2"
              className="me-2"
            />

            <h6 className="mb-0 flex-1">Active Campaigns</h6>
            <div>{/* <CardDropdown /> */}</div>
          </Flex>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0"
        >
          <div>
            <h2 className="fw-normal text-700 mb-1 lh-1">
              {filterActiveCampaigns().length}
            </h2>
            <Badge pill bg="200" className="text-danger fs--2"></Badge>
            <span className="fs--2 mb-0 mt-0 text-nowrap"></span>
          </div>
          <div className="ps-0 mt-n4">
            {/* <BasicECharts
              echarts={echarts}
              options={getOptions(data)}
              style={{ width: '5.5rem', height: 45 }}
            /> */}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

CampaignStatsCard.propTypes = {
  data: PropTypes.array
};

export default CampaignStatsCard;
