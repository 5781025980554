import React, { memo, useEffect, useState } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from '../nodes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CallNode = ({ data }) => {
  CallNode.propTypes = {
    data: PropTypes.object.isRequired
  };

  const [destination, setDestination] = useState(data.inputs.call.choices[0]);

  useEffect(() => {
    data.inputs.call.destination = destination;
  }, [destination]);

  return (
    <>
      <div className={cx(styles.nodeWrapper)}>
        <div className={cx(styles.nodeTitleBar)}>
          <div>
            <FontAwesomeIcon icon="phone" />
          </div>
          <div className={cx(styles.nodeTitle)}>Call Lead</div>
        </div>
        <div className={cx(styles.nodeContent)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '4px',
              alignItems: 'center'
            }}
          >
            <div style={{ flex: 1 }}>
              <div className={cx(styles.nodeContentTitle)}>Destination</div>
            </div>
            <div style={{ flex: 1 }}>
              <select
                className={cx(styles.destinationSelect)}
                value={destination}
                onChange={e => setDestination(e.target.value)}
              >
                {data.inputs.call.choices.map((d, index) => (
                  <option key={index} value={d}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="dispositions-tooltip">{'Dispositions'}</Tooltip>}
      >
        <Handle
          id="disposition"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '70px' }}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="data-tooltip">{'Data'}</Tooltip>}
      >
        <Handle
          id="data"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '90px' }}
        />
      </OverlayTrigger>
    </>
  );
};

export default memo(CallNode);
