import React from 'react';

const Error403 = () => {
  return (
    <div>
      <h1>403</h1>
      <p>Forbidden</p>
    </div>
  );
};

export default Error403;
