import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PromptTable from './PromptTable';

const PromptTool = () => {
  return (
    <Row>
      <Col>
        <PromptTable />
      </Col>
    </Row>
  );
};

export default PromptTool;
