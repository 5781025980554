import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Dropdown,
  Modal,
  Alert
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import PromptTableHeader from './PromptTableHeader';
import { useAutoResizeTextarea } from 'hooks/useAutoResizeTextarea';
import { viewPrompts, modifyPrompt } from 'helpers/api/Prompts';

const PromptTable = () => {
  const [prompts, setPrompts] = useState([]);
  const [prompt, setPrompt] = useState({
    prompt_id: '',
    prompt: '',
    label: '',
    temp: null,
    top_p: null
  });
  const [showModal, setShowModal] = useState(false);
  const textareaRef = useRef(null);

  useAutoResizeTextarea(textareaRef, prompt?.prompt, showModal);

  const fetchPrompts = async () => {
    try {
      const response = await viewPrompts();
      if (response.status === 200) {
        setPrompts(response.data);
      } else {
        console.error('Failed to fetch prompts');
      }
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  const handleShowModal = prompt => {
    setPrompt(prompt);
    setShowModal(true);
  };

  const handleUpdatePrompt = async (prompt_id, prompt) => {
    try {
      const response = await modifyPrompt(prompt_id, { prompt: prompt.prompt });
      if (response.status === 200) {
        console.log(response);
        fetchPrompts();
        toast.success('Prompt updated', {
          position: 'top-right',
          autoClose: 5000
        });
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error updating prompt:', error);
      toast.error('Failed to update prompt', {
        position: 'top-right',
        autoClose: 5000
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Label',
        accessor: 'label',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1'
        },
        Cell: rowData => {
          const { label } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark fw-semi-bold">
              <div className="flex-1">{label}</div>
            </div>
          );
        }
      },
      {
        Header: 'Approved',
        accessor: 'approved',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'pe-1'
        },
        Cell: rowData => {
          const { approved } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark">
              <div className="flex-1">{approved ? 'Yes' : 'No'} </div>
            </div>
          );
        }
      },
      {
        Header: 'Approved By',
        accessor: 'approved_by',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'pe-1'
        },
        Cell: rowData => {
          const { approved_by } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark">
              <div className="flex-1">{approved_by ? approved_by : 'N/A'}</div>
            </div>
          );
        }
      },
      {
        Header: 'Temp',
        accessor: 'temp',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'pe-1'
        },
        Cell: rowData => {
          const { temp } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark">
              <div className="flex-1">{temp}</div>
            </div>
          );
        }
      },
      {
        Header: 'Top P',
        accessor: 'top_p',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'pe-1'
        },
        Cell: rowData => {
          const { top_p } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark">
              <div className="flex-1">{top_p}</div>
            </div>
          );
        }
      },
      {
        Header: 'Version',
        accessor: 'version',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'pe-1'
        },
        Cell: rowData => {
          const { version } = rowData.row.original;
          return (
            <div className="d-flex align-items-center text-dark">
              <div className="flex-1">{version}</div>
            </div>
          );
        }
      },
      {
        Header: 'Actions',
        accessor: 'prompt_id',
        Cell: rowData => {
          const [promptData, setPromptData] = useState({});

          useEffect(() => {
            setPromptData(rowData.row.original);
          }, [rowData]);

          return (
            <Dropdown drop="start">
              <div className="py-2">
                <Dropdown.Toggle variant="link" className="text-dark">
                  ...
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleShowModal(promptData)}>
                    Edit
                  </Dropdown.Item>
                </Dropdown.Menu>
              </div>
            </Dropdown>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <Row>
        <Col>
          <AdvanceTableWrapper
            columns={columns}
            data={prompts}
            sortable
            filterable
            pagination
            selection
            perPage={15}
            rowCount={prompts.length}
          >
            <Card>
              <Card.Header>
                <PromptTableHeader table />
              </Card.Header>

              <Card.Body>
                {prompts.length > 0 ? (
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger text-nowrap align-middle"
                    tableProps={{
                      size: 'sm',
                      className: 'fs--1 mb-0 overflow-auto'
                    }}
                  />
                ) : (
                  <Alert variant="info" className="text-center">
                    No prompts found
                  </Alert>
                )}
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  table
                  pagination
                  navButtons
                  rowCount={prompts.length}
                  rowInfo
                  pagesCount={prompts.length / 15}
                />
              </Card.Footer>
            </Card>
          </AdvanceTableWrapper>
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{prompt.prompt_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Temp</Form.Label>
                  <Form.Control
                    type="number"
                    name="temp"
                    step={0.1}
                    value={prompt.temp}
                    onChange={e =>
                      setPrompt({ ...prompt, temp: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Top P</Form.Label>
                  <Form.Control
                    type="number"
                    name="top_p"
                    step={0.1}
                    value={prompt.top_p}
                    onChange={e =>
                      setPrompt({ ...prompt, top_p: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Label</Form.Label>
                  <Form.Control
                    type="text"
                    name="label"
                    value={prompt.label}
                    onChange={e =>
                      setPrompt({ ...prompt, label: e.target.value })
                    }
                    placeholder="Enter Label"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Prompt</Form.Label>
              <Form.Control
                as="textarea"
                value={prompt.prompt}
                onChange={e => setPrompt({ ...prompt, prompt: e.target.value })}
                ref={textareaRef}
                style={{ overflowY: 'hidden' }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleUpdatePrompt(prompt.prompt_id, prompt)}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromptTable;
