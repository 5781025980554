import axiosWithCreds from '../../axios/axios';
export const publishableKey = async () => {
  try {
    const response = await axiosWithCreds.get(`stripe/pub-key`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const testWebhook = async data => {
  try {
    const response = await axiosWithCreds.post(`stripe/notify/test`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const liveWebhook = async data => {
  try {
    const response = await axiosWithCreds.post(`stripe/notify/live`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createSubscription = async data => {
  try {
    const response = await axiosWithCreds.post(`stripe/subscription`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewCustomer = async () => {
  try {
    const response = await axiosWithCreds.get(`stripe/customer`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
