import React, { memo } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from './nodes.module.css';

import WebhookNode from './action/WebhookNode';

const ActionNode = ({ data }) => {
  ActionNode.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  return (
    <div className={cx(styles.node + ' ' + styles.nodeAction)}>
      <Handle
        id="target"
        className={cx(styles.handleAction)}
        type="target"
        position={Position.Top}
        isConnectable={true}
      />
      {data.function === 'webhook' && <WebhookNode data={data} />}
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="event-tooltip">{'Event'}</Tooltip>}
      >
        <Handle
          id="event"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Right}
          isConnectable={true}
        />
      </OverlayTrigger>
    </div>
  );
};

export default memo(ActionNode);
