import React, { createContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { viewOrganization } from 'helpers/api/Organization';

const SubscriptionContext = createContext({});

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      setIsLoading(true);
      try {
        const response = await viewOrganization();
        if (response.status === 200) {
          console.log(response?.data?.activated);
          setSubscription(response?.data?.activated);
        } else {
          setError(response.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const subscriptionValue = useMemo(
    () => ({ subscription, isLoading, error }),
    [subscription, isLoading, error]
  );

  return (
    <SubscriptionContext.Provider value={subscriptionValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SubscriptionContext;
