export const destinationData = [
  {
    id: 1,
    name: 'Call Center 1',
    number: '1234567890',
    openTime: '08:00',
    closeTime: '17:00',
    weekdaysOnly: true,
    breakStart: '12:00',
    breakEnd: '13:00',
    timezone: 'America/New_York'
  },
  {
    id: 2,
    name: 'Call Center 2',
    number: '1234567890',
    openTime: '08:00',
    closeTime: '17:00',
    weekdaysOnly: true,
    breakStart: '12:00',
    breakEnd: '13:00',
    timezone: 'America/Los_Angeles'
  },
  {
    id: 3,
    name: 'Call Center 3',
    number: '1234567890',
    openTime: '08:00',
    closeTime: '17:00',
    weekdaysOnly: true,
    breakStart: '12:00',
    breakEnd: '13:00',
    timezone: 'America/Chicago'
  }
];
