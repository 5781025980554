import ActionNode from './ActionNode';
import CallNodes from './CallNodes';
import SmsNodes from './SmsNodes';
import ModNode from './ModNode';
import RootNode from './RootNode';
import UtilityNode from './UtilityNode';
import CloseLeadsNode from './CloseLeadsNode';

const nodeTypes = {
  action: ActionNode,
  call: CallNodes,
  sms: SmsNodes,
  mod: ModNode,
  root: RootNode,
  close_leads: CloseLeadsNode,
  utility: UtilityNode
};

export default nodeTypes;
