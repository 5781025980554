import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

//import BillingForm from './BillingForm';
// import CheckoutForm from './CheckoutForm';
import Subscription from './Subscription';

const stripeTestPk = process.env.REACT_APP_STRIPE_TEST_PK;
const stripePromise = loadStripe(stripeTestPk);

const Payment = () => {
  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#47b5ff'
    }
  };
  //stripe options
  const options = {
    appearance,
    mode: 'subscription',
    currency: 'usd',
    amount: 105000
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <Subscription />
      </Elements>
    </>
  );
};

export default Payment;
