import React, { memo } from 'react';

const Toolbox = () => {
  const modFunctions = ['wait'];
  const actionFunctions = ['webhook'];
  const callFunctions = ['call'];
  const smsFunctions = ['sms'];
  const closeLeadsFunctions = ['close_leads'];
  const utilityFunctions = ['unpack_disposition'];

  const titleCase = str => {
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  };

  const onDragStart = (event, nodeType, nodeFunction) => {
    const nodeData = JSON.stringify({
      type: nodeType,
      function: nodeFunction
    });

    event.dataTransfer.setData('application/reactflow', nodeData);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="toolbox">
      {modFunctions.map(f => (
        <div
          key={'mod-' + f}
          className="toolboxNode mod"
          onDragStart={event => onDragStart(event, 'mod', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
      {actionFunctions.map(f => (
        <div
          key={'action-' + f}
          className="toolboxNode action"
          onDragStart={event => onDragStart(event, 'action', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
      {callFunctions.map(f => (
        <div
          key={'call-' + f}
          className="toolboxNode call"
          onDragStart={event => onDragStart(event, 'call', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
      {smsFunctions.map(f => (
        <div
          key={'sms-' + f}
          className="toolboxNode sms"
          onDragStart={event => onDragStart(event, 'sms', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
      {closeLeadsFunctions.map(f => (
        <div
          key={'close_leads-' + f}
          className="toolboxNode close_leads"
          onDragStart={event => onDragStart(event, 'close_leads', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
      {utilityFunctions.map(f => (
        <div
          key={'utility-' + f}
          className="toolboxNode utility"
          onDragStart={event => onDragStart(event, 'utility', f)}
          draggable
        >
          {titleCase(f.replace('_', ' '))}
        </div>
      ))}
    </div>
  );
};

export default memo(Toolbox);
