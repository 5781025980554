import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';

const ConfirmMailContent = ({ titleTag: TitleTag }) => {
  const location = useLocation();
  const email = location.state?.email || '';

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>Please check your email!</TitleTag>
      <p>
        An email has been sent to <strong>{email}</strong>. Please click on the
        included link to verify your account.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/login`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to login
      </Button>
    </>
  );
};

ConfirmMailContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
