import React, { useState, useEffect, useMemo } from 'react';
import { Card, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import DestinationTableHeader from './DestinationTableHeader';
import CardDropdown from 'components/common/CardDropdown';

// import { fakeDestinations } from './fakeDestinations';
import { viewDestinations, deleteDestination } from 'helpers/api/Destination';

const DestinationsTables = () => {
  const [destinations, setDestinations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);

  useEffect(() => {
    const fetchDestinations = async () => {
      const response = await viewDestinations();
      if (response.status === 200) {
        console.log(response.data);
        setDestinations(response.data);
      } else {
        toast.error('Failed to fetch destinations', {
          autoClose: 3000
        });
      }
    };
    fetchDestinations();
  }, []);

  const handleDelete = async id => {
    const response = await deleteDestination(id);
    if (response.status === 200) {
      setDestinations(prevDestinations =>
        prevDestinations.filter(
          destination => destination.destination_id !== id
        )
      );
      toast.success('Destination deleted successfully', {
        autoClose: 3000
      });
    } else {
      toast.error('Failed to delete destination', {
        autoClose: 3000
      });
    }
  };

  const refreshDestinations = async () => {
    const response = await viewDestinations();
    if (response.status === 200) {
      console.log(response.data);
      setDestinations(response.data);
    } else {
      toast.error('Failed to fetch destinations', {
        autoClose: 3000
      });
    }
  };

  const handleEdit = destination => {
    setSelectedDestination(destination);
    setShowModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1'
        },
        Cell: rowData => {
          const { name } = rowData.row.original;
          return (
            <div className="text-dark fw-bold fs-0">
              <span>{name}</span>
            </div>
          );
        }
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { phone_number } = rowData.row.original;
          return (
            <div className="text-dark fw-semi-bold">
              <span>{phone_number}</span>
            </div>
          );
        }
      },
      // {
      //   Header: 'Destination',
      //   accessor: 'destination',
      //   headerProps: { className: 'pe-7' },
      //   Cell: rowData => {
      //     const { destination } = rowData.row.original;
      //     return (
      //       <div className="text-dark fw-semi-bold">
      //         <span>{destination}</span>
      //       </div>
      //     );
      //   }
      // },
      {
        Header: 'Timezone',
        accessor: 'timezone',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { timezone } = rowData.row.original;
          return (
            <div className="text-dark fw-semi-bold">
              <span>{timezone}</span>
            </div>
          );
        }
      },
      {
        Header: 'Payout',
        accessor: 'payout',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { payout } = rowData.row.original;
          return (
            <div className="text-dark fw-semi-bold">
              <span>{payout}</span>
            </div>
          );
        }
      },
      {
        Header: 'Disposition',
        accessor: 'disposition',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { disposition } = rowData.row.original;
          return (
            <div className="text-dark fw-semi-bold">
              <span>{disposition}</span>
            </div>
          );
        }
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { notes } = rowData.row.original;
          return (
            <div className="text-dark fw-semi-bold">
              <span>{notes}</span>
            </div>
          );
        }
      },
      {
        Header: 'Actions',
        accessor: 'destination_id',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { destination_id } = rowData.row.original;
          return (
            <CardDropdown>
              <Dropdown.Item
                className="text-dark"
                onClick={() => handleEdit(rowData.row.original)}
              >
                View
              </Dropdown.Item>
              <Dropdown.Item
                className="text-warning"
                onClick={() => handleEdit(rowData.row.original)}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => handleDelete(destination_id)}
              >
                Delete
              </Dropdown.Item>
            </CardDropdown>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={destinations}
        sortable
        filterable
        pagination
        perPage={15}
        rowCount={destinations.length}
      >
        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Header>
                <DestinationTableHeader
                  table
                  refreshDestinations={refreshDestinations}
                  onHide={() => setShowModal(false)}
                  destination={selectedDestination}
                  show={showModal}
                  mode={selectedDestination ? 'edit' : 'add'}
                />
              </Card.Header>
              <Card.Body>
                {destinations.length > 0 ? (
                  <div style={{ overflowY: 'auto', height: '50vh' }}>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="btn-reveal-trigger text-nowrap align-middle"
                      tableProps={{
                        size: 'sm',
                        className: 'fs--1 mb-0 overflow-auto'
                      }}
                    />
                  </div>
                ) : (
                  <Alert variant="info" className="text-center">
                    No destinations found click on the Add Destination button to
                    add a new destination
                  </Alert>
                )}
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  table
                  rowCount={destinations.length}
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </AdvanceTableWrapper>
    </>
  );
};

export default DestinationsTables;
