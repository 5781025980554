import React, { useContext, useMemo, useState } from 'react';
import Papa from 'papaparse';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Form,
  Card,
  Col,
  Row,
  ProgressBar,
  Alert,
  Modal,
  Button
} from 'react-bootstrap';
import { CampaignContext } from 'context/Context';
import Divider from 'components/common/Divider';
import * as Yup from 'yup';

import { states } from 'data/states';

const leadFormSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone1: Yup.string()
    .matches(
      /^(?:\+1)?(?:\([2-9]\d{2}\)|[2-9]\d{2})[ -]?\d{3}[ -]?\d{4}$/,
      'Invalid phone number'
    )
    .required('Phone number is required'),
  phone2: Yup.string().matches(
    /^(?:\+1)?(?:\([2-9]\d{2}\)|[2-9]\d{2})[ -]?\d{3}[ -]?\d{4}$/,
    'Invalid phone number'
  ),
  state: Yup.string().required('State is required'),
  zip_code: Yup.string()
    .matches(/^\d{5}$/, 'Invalid Zip Code')
    .required('Zip Code is required'),
  city: Yup.string().required('City is required'),
  source: Yup.string().required('Source is required'),
  tags: Yup.string().matches(
    /^[\w\s]+(?:,[\w\s]+)*$/,
    'Tags must be comma-separated words'
  )
});

const CampaignLeads = () => {
  const { leads, setLeads, lead, setLead } = useContext(CampaignContext);
  const [failedCount, setFailedCount] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFileUpload = e => {
    const file = e.target.files[0];
    parseCSV(file);
  };

  const parseCSV = file => {
    Papa.parse(file, {
      complete: result => {
        setTotalLeads(result.data.length);
        const validLeads = [];
        let failedCount = 0;

        result.data.forEach(lead => {
          if (lead.phone1 && lead.phone1.trim() !== '') {
            validLeads.push(lead);
          } else {
            failedCount++;
          }
        });

        setLeads(validLeads);
        setFailedCount(failedCount);
      },
      header: true,
      skipEmptyLines: true,
      error: error => {
        console.error('Error parsing CSV', error);
      }
    });
  };

  const handleManualEntry = () => {
    leadFormSchema
      .validate(lead, { abortEarly: false })
      .then(() => {
        setErrors({});
        setLeads([...leads, lead]);
        setLead({});
        setShow(false);
      })
      .catch(err => {
        const errors = {};
        err.inner.forEach(e => {
          if (!errors[e.path]) {
            errors[e.path] = [];
          }
          errors[e.path].push(e.message);
        });
        setErrors(errors);
      });
  };

  const handleClose = () => {
    setLead({});
    setShow(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'first_name',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1 text-dark'
        }
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1 text-dark'
        }
      },
      {
        Header: 'Phone',
        accessor: 'phone1',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1 text-dark'
        }
      },
      {
        Header: 'State',
        accessor: 'state',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1 text-dark'
        }
      },
      {
        Header: 'Zip',
        accessor: 'zip',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1 text-dark'
        }
      }
    ],
    []
  );

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <ProgressBar>
            <ProgressBar
              variant="success"
              now={(leads.length / totalLeads) * 100}
              key={1}
              striped
            />
            <ProgressBar
              variant="danger"
              now={(failedCount / totalLeads) * 100}
              key={2}
              striped
            />
          </ProgressBar>

          {leads.length > 0 && (
            <Row className="mt-3">
              <Col md={6} lg={12}>
                <Alert variant="danger" className="mt-3" dismissible>
                  {failedCount} leads failed to upload due to missing phone
                  number
                </Alert>

                <Alert variant="success" className="mt-3" dismissible>
                  {leads.length} leads uploaded successfully
                </Alert>
              </Col>
            </Row>
          )}
        </Card.Header>
        <Card.Body className="border-bottom">
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                />
              </Form.Group>
              <p className="text-muted fs--1">
                Upload a CSV file with the following columns: first_name,
                last_name, phone1, state, zip
              </p>
              <Divider />
              <p className="text-muted fs--1">
                Or manually enter leads by clicking the button below
              </p>
              <Button
                size="sm"
                variant="primary mb-3"
                onClick={() => setShow(true)}
              >
                Manual Entry
              </Button>
              <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>Lead Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form noValidate>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formFirstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="first name"
                            placeholder="Enter first name"
                            onChange={e =>
                              setLead({ ...lead, first_name: e.target.value })
                            }
                          />

                          {errors.first_name && (
                            <Form.Text className="text-danger">
                              {errors.first_name}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formLastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="last name"
                            placeholder="Enter last name"
                            onChange={e =>
                              setLead({ ...lead, last_name: e.target.value })
                            }
                          />
                          {errors.last_name && (
                            <Form.Text className="text-danger">
                              {errors.last_name}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formPhone1">
                          <Form.Label>Primary Phone</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone1"
                            placeholder="Enter phone number"
                            onChange={e =>
                              setLead({ ...lead, phone1: e.target.value })
                            }
                          />
                          {errors.phone1 && (
                            <Form.Text className="text-danger">
                              {errors.phone1}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formPhone2">
                          <Form.Label>Secondary Phone</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone2"
                            placeholder="Enter phone number"
                            onChange={e =>
                              setLead({ ...lead, phone2: e.target.value })
                            }
                          />

                          {errors.phone2 && (
                            <Form.Text className="text-danger">
                              {errors.phone2}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row></Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formCity">
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder="Enter city"
                            onChange={e =>
                              setLead({ ...lead, city: e.target.value })
                            }
                          />

                          {errors.city && (
                            <Form.Text className="text-danger">
                              {errors.city}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3" controlId="formState">
                          <Form.Label>State</Form.Label>
                          <Form.Select
                            aria-label="State"
                            name="state"
                            onChange={e =>
                              setLead({ ...lead, state: e.target.value })
                            }
                          >
                            <option>Select State</option>
                            {states.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </Form.Select>
                          {errors.state && (
                            <Form.Text className="text-danger">
                              {errors.state}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3" controlId="formZipCode">
                          <Form.Label>Zip</Form.Label>
                          <Form.Control
                            type="text"
                            name="zip_code"
                            placeholder="Enter zip"
                            onChange={e =>
                              setLead({ ...lead, zip_code: e.target.value })
                            }
                          />
                          {errors.zip_code && (
                            <Form.Text className="text-danger">
                              {errors.zip_code}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formSource">
                      <Form.Label>Source</Form.Label>
                      <Form.Control
                        type="text"
                        name="source"
                        placeholder="Enter source"
                        onChange={e =>
                          setLead({ ...lead, source: e.target.value })
                        }
                      />
                      {errors.source && (
                        <Form.Text className="text-danger">
                          {errors.source}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formTags">
                      <Form.Label>Tags</Form.Label>
                      <Form.Control
                        as="textarea"
                        aria-rowspan={3}
                        name="tags"
                        placeholder="Enter tags"
                        onChange={e =>
                          setLead({ ...lead, tags: e.target.value })
                        }
                      />
                      {errors.tags ? (
                        <Form.Text className="text-danger">
                          {errors.tags}
                        </Form.Text>
                      ) : (
                        <Form.Text className="text-muted">
                          Comma separated tags
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formNotes">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        aria-rowspan={3}
                        name="notes"
                        placeholder="Enter notes"
                        onChange={e =>
                          setLead({ ...lead, notes: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => handleClose()}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={() => handleManualEntry()}>
                    Create Lead
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Divider />

      <AdvanceTableWrapper
        columns={columns}
        data={leads}
        pagination
        perPage={10}
        responsive
        rowCount={leads.length}
        sortable
        filterable
      >
        <Card>
          <Card.Header>
            <p className="text-muted fs--1">Parsed Leads from CSV</p>
          </Card.Header>
          <Card.Body>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="btn-reveal-trigger text-nowrap align-middle"
              tableProps={{
                size: 'sm',
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              table
              rowCount={leads.length}
              rowInfo
              navButtons
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default CampaignLeads;
