import React, { memo } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from './nodes.module.css';

const RootNode = ({ data }) => {
  RootNode.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  return (
    <div className={cx(styles.node + ' ' + styles.nodeRoot)}>
      {data.function === 'inbound' ? 'DID Called' : 'Lead Posted'}

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="trigger-tooltip">{'Trigger'}</Tooltip>}
      >
        <Handle
          tooltip="Trigger"
          id="trigger"
          className={cx(styles.handleRoot)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '70px' }}
        >
          {/* <div className={cx(styles.handleTooltipWrapper)}>
          <span className={cx(styles.handleTooltip)}>{'Trigger'}</span>
        </div> */}
        </Handle>
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="data-tooltip">{'Data'}</Tooltip>}
      >
        <Handle
          id="data"
          className={cx(styles.handleRoot)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '90px' }}
        >
          {/* <div className={cx(styles.handleTooltipWrapper)}>
          <span className={cx(styles.handleTooltip)}>{'Data'}</span>
        </div> */}
        </Handle>
      </OverlayTrigger>

      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="event-tooltip">{'Event'}</Tooltip>}
      >
        <Handle
          id="event"
          className={cx(styles.handleRoot)}
          type="source"
          position={Position.Right}
          isConnectable={true}
        >
          {/* <div className={cx(styles.handleTooltipWrapper)}>
          <span className={cx(styles.handleTooltip)}>{'Event'}</span>
        </div> */}
        </Handle>
      </OverlayTrigger>
    </div>
  );
};

export default memo(RootNode);
