import React, { useState, useEffect, useMemo } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { createSubscription, viewCustomer } from 'helpers/api/Stripe';
import AuthCardLayout from 'layouts/AuthCardLayout';
import Divider from 'components/common/Divider';

const PRICE_PER_AGENT = 1050; // Use an environment variable here

const Subscription = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [quantity, setQuantity] = useState(25);
  const [customerId, setCustomerId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const total = useMemo(() => quantity * PRICE_PER_AGENT, [quantity]);

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const response = await viewCustomer();
        console.log(response);
        setCustomerId(response.customer_id);
      } catch (error) {
        console.error(error);
        setError('Failed to fetch customer data');
      }
    };
    getCustomer();
  }, []);

  // useEffect(() => {
  //   const handBeforeUnload = e => {
  //     if (isSubScriptionStarted) {
  //       e.preventDefault();
  //       e.returnValue = '';
  //     }
  //   };
  //   window.addEventListener('beforeunload', handBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handBeforeUnload);
  //   };
  // }, [isSubScriptionStarted]);

  const handleChange = e => {
    setQuantity(e.target.value);
    // setIsSubScriptionStarted(true);
  };
  // When using the PaymentElement, you must pass the client secret to stripe.confirmPayment.
  const handleSubmit = async e => {
    e.preventDefault(); // Prevents default refresh by the browser

    if (!stripe || !elements) {
      setError('Stripe has not loaded yet.');
      return;
    }

    setIsProcessing(true); // Start processing payment

    if (!clientSecret) {
      setError('Client secret is not available.');
      setIsProcessing(false);
      return;
    }

    try {
      // Have to call this before any synchronous code
      await elements.submit();

      // Then, we can confirm the payment MUST pass client secret to confirmPayment!!!!!!!!!!!!!!!!!!!!!!!!!!
      const result = await stripe.confirmPayment({
        clientSecret: clientSecret,
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`
        }
      });

      if (result.error) {
        setError(`Payment failed: ${result.error.message}`);
      } else {
        // The payment process is complete, handle the result
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError('An error occurred during the payment process.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubscriptionSelection = async () => {
    try {
      const response = await createSubscription({
        customerId: customerId,
        items: [{ price: 'price_1OZGCuED79Ae0HcrZYLm2ZL7', quantity: quantity }]
      });
      console.log(response);
      setClientSecret(response.data.client_secret);
    } catch (error) {
      console.error(error);
      setError('Failed to create subscription');
    }
  };

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  return (
    <AuthCardLayout
      leftSideContent={
        <>
          <h1 className="display-5 text-white mb-3">
            <strong>Subscription</strong>
          </h1>
          <p className="text-white">
            <strong>Subscribe to VAgents</strong>
          </p>
        </>
      }
      middleContent={
        <Form>
          <Row className="mb-3 align-items-center justify-content-center">
            <Col xs={6}>
              <p className="text-white">Price per Agent ${PRICE_PER_AGENT}</p>
            </Col>
          </Row>
          <Row className="mb-3 align-items-center justify-content-center">
            <Col xs={6} className="mb-3">
              <Form.Group className="mb-3" controlId="quantity">
                <Form.Label className="text-white">Number of Agents</Form.Label>
                <Form.Control
                  type="number"
                  min="25"
                  max="100"
                  value={quantity}
                  onChange={handleChange}
                  disabled={isChecked}
                />
                <Form.Text className="text-muted">
                  Minimum of 25 agent's
                </Form.Text>
                <Form.Check className="mt-3">
                  <Form.Check.Input
                    type="radio"
                    id="confirmTOS"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </Form.Check>
                <Form.Check.Label className="text-white ml-2">
                  <span className="text-white">Agree to TOS</span>
                </Form.Check.Label>
              </Form.Group>
            </Col>
          </Row>
          {!isChecked ? (
            <Row className="mb-3 align-items-center justify-content-center">
              <Col xs={6}>
                <p className="text-white">
                  Total: <strong>${total}</strong>
                </p>
              </Col>
            </Row>
          ) : (
            <Row className="mb-3 align-items-center justify-content-center">
              <Col xs={12}>
                <p className="text-white">
                  Total: <strong>${total}</strong>
                </p>
                <Button
                  variant="primary"
                  onClick={handleSubscriptionSelection}
                  disabled={isProcessing}
                >
                  Confirm Subscription
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col>{error && <p className="text-danger">{error}</p>}</Col>
          </Row>
        </Form>
      }
      footer={false}
    >
      {(clientSecret && <PaymentElement clientSecret={clientSecret} />) || (
        <div className="text-center">
          <p className="text-dark">Select a subscription to continue</p>
        </div>
      )}
      <Divider />
      {clientSecret && (
        <Button
          variant="primary"
          type="submit"
          disabled={isProcessing}
          onClick={handleSubmit}
        >
          Subscribe
        </Button>
      )}
    </AuthCardLayout>
  );
};

export default Subscription;
