import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionByBrowser from './session-by-browser/SessionByBrowser';
import {
  intelligence,
  sessionByBrowser,
  sessionByCountry,
  topPagesTableData
} from 'data/dashboard/analytics';
import { countryData } from 'data/countryData';
import UsersByCountry from './users-by-country/UsersByCountry';
import Intelligence from './Intelligence';
import ActiveUsers from './active-users/ActiveUsers';
import CampaignPerfomance from './campaign-perfomance/CampaignPerfomance';
import UsersAtTime from './users-at-a-time/UsersAtTime';
import BounceRate from './bounce-rate/BounceRate';
import TrafficSource from './traffic-source/TrafficSource';
import Stats from './stats/Stats';
import TopPages from './top-pages/TopPages';
import PageHeader from 'components/common/PageHeader';

const Analytics = () => {
  return (
    <>
      <Row className="gx-3">
        <Col lg={12}>
          <Card className="mb-3">
            <Card.Body>
              <PageHeader
                title="Custom Reports"
                description="Reports are not a one-size-fits-all solution. VAgents tailors each report to your specific needs during our onboarding process."
                className="mb-3"
              >
                <Button
                  href={`https://www.vagents.com/`}
                  target="_blank"
                  variant="link"
                  size="sm"
                  className="ps-0"
                >
                  Learn more about custom reports & our onboarding process
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-1 fs--2"
                  />
                </Button>
              </PageHeader>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col xxl={8}></Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={4}>
          <SessionByBrowser data={sessionByBrowser} />
        </Col>
        <Col md={4}>
          <UsersByCountry chartData={sessionByCountry} mapData={countryData} />
        </Col>
        <Col md={4}>
          <Intelligence data={intelligence} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7}>
          <Row className="align-items-stretch h-100">
            <Col xs={12} className="mb-3">
              <ActiveUsers className="mb-3 h-100" />
            </Col>
            <Col xs={12}>
              <Stats />
            </Col>
          </Row>
        </Col>
        <Col lg={5}>
          <CampaignPerfomance />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={5} xxl={4}>
          <UsersAtTime />
        </Col>
        <Col lg={7} xxl={8}>
          <TopPages tableData={topPagesTableData} />
        </Col>
      </Row>

      <Row className="g-3">
        <Col lg={5} xxl={6}>
          <BounceRate />
        </Col>
        <Col lg={7} xxl={6}>
          <TrafficSource />
        </Col>
      </Row>
    </>
  );
};

Analytics.propTypes = {};

export default Analytics;
