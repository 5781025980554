import React, { memo, useEffect, useState } from 'react';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from '../nodes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WaitNode = ({ data }) => {
  WaitNode.propTypes = {
    data: PropTypes.object.isRequired
  };

  const [timeUnitType, setTimeUnitType] = useState(
    data.inputs.wait.timeUnitType ? data.inputs.wait.timeUnitType : 'for'
  );
  const [timeInMinutes, setTimeInMinutes] = useState(
    data.inputs.wait.timeInMinutes ? data.inputs.wait.timeInMinutes : 15
  );

  useEffect(() => {
    data.inputs.wait.timeUnitType = timeUnitType;
  }, [timeUnitType]);

  useEffect(() => {
    data.inputs.wait.timeInMinutes = timeInMinutes;
  }, [timeInMinutes]);

  return (
    <div className={cx(styles.nodeWrapper)}>
      <div className={cx(styles.nodeTitleBar)}>
        <div>
          <FontAwesomeIcon icon="clock" />
        </div>
        <div className={cx(styles.nodeTitle)}>
          Wait
          <select
            className={cx(styles.timeUnitType)}
            value={timeUnitType}
            onChange={e => setTimeUnitType(e.target.value)}
          >
            <option value="for">for</option>
          </select>
        </div>
      </div>
      <div className={cx(styles.nodeContent)}>
        {timeUnitType === 'for' && (
          <div className={cx(styles.timeInput)}>
            <select
              className={cx(styles.timeUnit)}
              value={timeInMinutes}
              onChange={e => setTimeInMinutes(e.target.value)}
            >
              <option value="1">1 Minute</option>
              <option value="5">5 Minutes</option>
              <option value="10">10 Minutes</option>
              <option value="15">15 Minutes</option>
              <option value="30">30 Minutes</option>
              <option value="60">1 Hour</option>
              <option value="120">2 Hours</option>
              <option value="180">3 Hours</option>
              <option value="240">4 Hours</option>
              <option value="300">5 Hours</option>
              <option value="360">6 Hours</option>
              <option value="420">7 Hours</option>
              <option value="480">8 Hours</option>
              <option value="540">9 Hours</option>
              <option value="600">10 Hours</option>
              <option value="660">11 Hours</option>
              <option value="720">12 Hours</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(WaitNode);
