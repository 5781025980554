import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconItem from '../../common/icon/IconItem';
// import CardDropdown from '../../common/CardDropdown';
// import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import CountUp from 'react-countup';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
// import BasicECharts from 'components/common/BasicEChart';
import { viewOrganization } from 'helpers/api/Organization';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

// const getOptions = data => ({
//   tooltip: {
//     trigger: 'axis',
//     formatter: '<strong>{b0}</strong> : {c0}'
//   },
//   xAxis: {
//     data: ['Week 4', 'Week 5', 'week 6', 'week 7']
//   },
//   series: [
//     {
//       type: 'line',
//       data,
//       smooth: true,
//       lineStyle: {
//         width: 3
//       },

//       areaStyle: {
//         color: {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             {
//               offset: 0,
//               color: rgbaColor(getColor('primary'), 0.25)
//             },
//             {
//               offset: 1,
//               color: rgbaColor(getColor('primary'), 0)
//             }
//           ]
//         }
//       }
//     }
//   ],
//   grid: {
//     bottom: '2%',
//     top: '2%',
//     right: '0px',
//     left: '10px'
//   }
// });

const TotalActiveAgents = () => {
  const [agentCount, setAgentCount] = useState([]);

  useEffect(() => {
    const fetchAgents = async () => {
      const response = await viewOrganization();
      setAgentCount(response.data.purchased_agents);
      console.log('agents', response.data.purchased_agents);
    };
    fetchAgents();
  }, []);

  return (
    <>
      <Card className="h-md-100">
        <Card.Header className="pb-0">
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mb-3 position-static"
          >
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip>
                  <p className="mb-0">Total number of agents not in use</p>
                </Tooltip>
              }
            >
              <IconItem
                tag="div"
                icon="phone"
                bg="soft-primary"
                color="primary"
                size="sm"
                iconClass="fs--2"
                className="me-2"
              />
            </OverlayTrigger>

            <h6 className="mb-0 flex-1">Available Agents</h6>

            {/* <div>
              <CardDropdown href="reports/calls" text="Calls Report" />
            </div> */}
          </Flex>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems="end"
          justifyContent="between"
          className="pt-0"
        >
          <div>
            <h2 className="fw-normal text-700 mb-1 lh-1">
              <CountUp
                start={0}
                end={agentCount}
                duration={2.75}
                delay={0}
                separator=","
              />
            </h2>
            <Badge pill bg="200" className="text-success fs--2"></Badge>
          </div>
          <div className="ps-0 mt-n4">
            {/* <BasicECharts
              echarts={echarts}
              options={getOptions(data)}
              style={{ width: '5.5rem', height: 45 }}
            /> */}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

TotalActiveAgents.propTypes = { data: PropTypes.array };

export default TotalActiveAgents;
