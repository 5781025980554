import * as yup from 'yup';

export const campaignSchema = yup.object().shape({
  campaign_name: yup.string().required('Campaign Name is required'),
  status: yup.string(),
  timezone: yup.string(),
  use_ai_voice_scheduling: yup.boolean(),
  use_ai_amd_scheduling: yup.boolean(),
  use_auto_close_leads: yup.boolean(),
  auto_forward_destination: yup.boolean(),
  auto_retry: yup.boolean(),
  dispatch_delay: yup.number(),
  raw_transfer: yup.boolean(),
  use_record_calls: yup.boolean(),
  number_querying: yup.boolean(),
  duration_transfer: yup.number(),
  outbound_calls_per_hour: yup.number(),
  outbound_sms_per_hour: yup.number(),
  voice_profile: yup.string(),
  use_custom_voice: yup.boolean(),
  retry_delay: yup.number(),
  delivery_method: yup.string(),
  close_lead_at_state: yup.string(),
  close_lead_action: yup.string(),
  archived: yup.boolean(),
  conversion_revenue: yup.number()
});
