import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const AuthWizardContext = createContext({ user: {} });

export const CampaignContext = createContext({
  newCampaign: {
    campaign_name: '',
    running_hours: {},
    flow: {},
    leads: []
  }
});

export default AppContext;
