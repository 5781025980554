import axiosWithCreds from '../../axios/axios';
export const login = async data => {
  try {
    const response = await axiosWithCreds.post(`user/login`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error(error.response.status);
    return error.response;
  }
};

export const logout = async () => {
  try {
    const response = await axiosWithCreds.post(`user/logout`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async () => {
  try {
    const response = await axiosWithCreds.get(`user`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const activateUser = async (otp, data) => {
  try {
    const response = await axiosWithCreds.post(`user/activate/${otp}`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const registerUser = async data => {
  try {
    const response = await axiosWithCreds.post(`user/register`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);

    // If there's an error response from the server, extract and throw it.
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Registration failed';

    throw new Error(errorMessage);
  }
};

export const forgotPassword = async data => {
  try {
    const response = await axiosWithCreds.post(`user/forgot-password`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async data => {
  try {
    const response = await axiosWithCreds.post('user/reset-password', data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};
