import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  Row,
  Col,
  Alert,
  ProgressBar,
  Card
} from 'react-bootstrap';
import Divider from 'components/common/Divider';
import axiosWithCreds from '../../axios/axios';

const DEFAULT_FILE_NAME = 'Choose File';

const getCampaignIdFromPath = path => path.split('/').pop();

const LeadUploader = () => {
  const { pathname } = useLocation();
  const campaign_id = getCampaignIdFromPath(pathname);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(DEFAULT_FILE_NAME);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [leadData, setLeadData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    title: '',
    preQualMethod: '',
    internalPreQual: '',
    bpoCount: 0,
    internalRepCount: 0
  });

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const handleFileUpload = async () => {
    setUploading(true);
    setError(null);
    setSuccess(false);

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axiosWithCreds.put(`campaign/${campaign_id}/leads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          const progress = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
          setProgress(progress);
        }
      });
      setUploading(false);
      setSuccess(true);
    } catch (err) {
      setError(err.message || 'Failed to upload');
      setUploading(false);
    }
  };

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h5 className="mb-3">Upload Leads</h5>
      </Card.Header>

      <Card.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Choose File or Input Manually </Form.Label>
                <Form.Control
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Form.Text className="text-muted">{fileName}</Form.Text>
            </Col>
          </Row>

          <Divider />

          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={leadData.firstName}
                  onChange={e =>
                    setLeadData({ ...leadData, firstName: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={leadData.lastName}
                  onChange={e =>
                    setLeadData({ ...leadData, lastName: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={leadData.email}
                  onChange={e =>
                    setLeadData({ ...leadData, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={leadData.phone}
                  onChange={e =>
                    setLeadData({ ...leadData, phone: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  value={leadData.company}
                  onChange={e =>
                    setLeadData({ ...leadData, company: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={leadData.title}
                  onChange={e =>
                    setLeadData({ ...leadData, title: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Select
                aria-label="Pre-Qualification Method"
                value={leadData.preQualMethod}
                onChange={e =>
                  setLeadData({ ...leadData, preQualMethod: e.target.value })
                }
              >
                <option value="">Pre-Qualification Method</option>
                <option value="BPO">BPO</option>
                <option value="Internal">Internal</option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Select
                aria-label="Internal Pre-Qualification"
                value={leadData.internalPreQual}
                onChange={e =>
                  setLeadData({ ...leadData, internalPreQual: e.target.value })
                }
              >
                <option value="">Internal Pre-Qualification Type</option>
                <option value="customer_service">Customer Service</option>
                <option value="human_resources">Human Resources</option>
              </Form.Select>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>BPO Count</Form.Label>
                <Form.Control
                  type="number"
                  value={leadData.bpoCount}
                  onChange={e =>
                    setLeadData({ ...leadData, bpoCount: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Internal Rep Count</Form.Label>
                <Form.Control
                  type="number"
                  value={leadData.internalRepCount}
                  onChange={e =>
                    setLeadData({
                      ...leadData,
                      internalRepCount: e.target.value
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button variant="primary" onClick={handleFileUpload}>
                Upload
              </Button>
            </Col>
          </Row>

          {error && (
            <Row>
              <Col>
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              </Col>
            </Row>
          )}

          {uploading && (
            <Row>
              <Col>
                <ProgressBar
                  now={progress}
                  label={`${progress}%`}
                  className="mt-3"
                />
              </Col>
            </Row>
          )}

          {success && (
            <Row>
              <Col>
                <Alert variant="success" className="mt-3">
                  File uploaded successfully
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default LeadUploader;
