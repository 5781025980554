import React from 'react';
import { Row, Col } from 'react-bootstrap';

import PageHeader from 'components/common/PageHeader';

import DestinationsTables from './DestinationsTables';

const Destinations = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageHeader title="Destinations" />
        </Col>
      </Row>

      <Row>
        <Col>
          <DestinationsTables />
        </Col>
      </Row>
    </>
  );
};

export default Destinations;
