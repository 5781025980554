import React from 'react';
import { Container, Card } from 'react-bootstrap';

const Tcpa = () => {
  return (
    <Container className="my-4">
      <Card>
        <Card.Header>
          <h5 className="mb-0">Telephone Consumer Protection Act</h5>
        </Card.Header>
        <Card.Body>
          <p className="text-dark">
            By submitting your information and checking the box above, I
            acknowledge that I have read and agree to this site's privacy policy
            and provide my electronic signature expressly consenting to be
            contacted at the email address or the number above, (including
            wireless number if provided) by insurance professionals or other
            companies offering related marketing or promotional offers that
            might be of interest to me by phone or email.
          </p>
          <p className="text-dark">
            I understand that these calls or SMS messages may be generated using
            an automated telephone dialing system, a pre-recorded message, or
            artificial voice. I understand that my telephone company may impose
            additional charges on the subscriber for such messages and that I am
            not required to provide this authorization to purchase goods or
            services. I further expressly consent that I am not required to
            provide this authorization to purchase goods or services. Receiving
            quotes through our website is always free and you are under no
            obligation to purchase any goods or services as a result of this
            request. Additional information related to California Consumer
            Privacy.
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Tcpa;
