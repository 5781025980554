import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Table, Card, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CampaignContext } from 'context/Context';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Dayjs Plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Timezones
const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu'
];

const CampaignHours = ({ updatedCampaignHours }) => {
  const { newCampaign, setNewCampaign } = useContext(CampaignContext);

  const [runningHours, setRunningHours] = useState({
    sunday: [{ open: '', close: '' }],
    monday: [{ open: '', close: '' }],
    tuesday: [{ open: '', close: '' }],
    wednesday: [{ open: '', close: '' }],
    thursday: [{ open: '', close: '' }],
    friday: [{ open: '', close: '' }],
    saturday: [{ open: '', close: '' }]
  });
  const [isOpen, setIsOpen] = useState(
    Object.keys(runningHours).reduce((acc, day) => {
      acc[day] = false;
      return acc;
    }, {})
  );

  const localTimezone = dayjs.tz.guess();
  const [timezone, setTimezone] = useState(localTimezone);

  const formatTimeWithOffset = time => {
    const offset = new Date().getTimezoneOffset();
    const offsetSign = offset > 0 ? '-' : '+';
    const setAbs = Math.abs(offset);
    const offsetHours = Math.floor(setAbs / 60)
      .toString()
      .padStart(2, '0');
    const offsetMinutes = (setAbs % 60).toString().padStart(2, '0');
    return `${time}${offsetSign}${offsetHours}:${offsetMinutes}`;
  };

  const handleOpenTimeChange = (day, index, e) => {
    const formattedTime = formatTimeWithOffset(e.target.value);
    setRunningHours(prevState => {
      const updatedState = { ...prevState };
      updatedState[day][index].open = formattedTime;
      return updatedState;
    });
  };

  const handleCloseTimeChange = (day, index, e) => {
    const formattedTime = formatTimeWithOffset(e.target.value);
    setRunningHours(prevState => {
      const updatedState = { ...prevState };
      updatedState[day][index].close = formattedTime;
      return updatedState;
    });
  };

  const handleAddRow = day => {
    setRunningHours(prevState => {
      const updatedState = { ...prevState };
      updatedState[day].push({ open: '', close: '' });
      return updatedState;
    });
  };

  const handleRemoveRow = (day, index) => {
    setRunningHours(prevState => {
      const updatedState = { ...prevState };
      updatedState[day].splice(index, 1);
      return updatedState;
    });
  };

  const getTimeDifference = timezone => {
    const localOffset = dayjs().tz(localTimezone).utcOffset();
    const timezoneOffset = dayjs().tz(timezone).utcOffset();
    const diffInHours = (timezoneOffset - localOffset) / 60;

    return diffInHours >= 0 ? `+${diffInHours}` : diffInHours;
  };

  const handleTimezoneChange = e => {
    const newTimezone = e.target.value;
    setTimezone(newTimezone);
  };

  const handleIsOpen = (e, day) => {
    const { checked } = e.target;
    setIsOpen(prevState => {
      const updatedState = { ...prevState };
      updatedState[day] = checked;
      return updatedState;
    });
  };

  useEffect(() => {
    setTimezone(localTimezone);
  }, [localTimezone]);

  useEffect(() => {
    if (runningHours) {
      updatedCampaignHours(runningHours);
    }
  }, [runningHours]);

  useEffect(() => {
    setNewCampaign({ ...newCampaign, running_hours: runningHours });
  }, [runningHours]);

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col md={3}>
            <Form.Select
              name="timezone"
              aria-label="choose timezone"
              value={timezone}
              onChange={handleTimezoneChange}
            >
              {timezones.map((tz, idx) => (
                <option key={idx} value={tz}>
                  {`(GMT${getTimeDifference(tz)}) ${tz}`}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr className="text-dark"></tr>
              </thead>
              <tbody>
                {Object.keys(runningHours).map(day => (
                  <React.Fragment key={day}>
                    {runningHours[day].map((item, index) => (
                      <tr key={index}>
                        {index === 0 && (
                          <td
                            rowSpan={runningHours[day].length}
                            className="align-middle text-dark fw-bold"
                            style={{ width: '10%' }}
                          >
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </td>
                        )}

                        <td className="align-middle text-dark fw-bold ">
                          <Form.Check
                            type="switch"
                            name={`running_hours.${day}[${index}].closed`}
                            label={isOpen[day] ? 'Open' : 'Closed'}
                            aria-label={`Closed on ${day}`}
                            checked={item.closed}
                            onChange={e => handleIsOpen(e, day)}
                            hidden={index > 0}
                          />
                        </td>

                        <td>
                          <Row className="g-2">
                            <Col md={4}>
                              <Form.Control
                                type="time"
                                name="open"
                                aria-label={`${day} open time`}
                                value={item.open.slice(0, 5)}
                                onChange={e =>
                                  handleOpenTimeChange(day, index, e)
                                }
                                disabled={!isOpen[day]}
                              />
                            </Col>

                            <Col md={4}>
                              <Form.Control
                                type="time"
                                name="close"
                                aria-label={`${day} close time`}
                                value={item.close.slice(0, 5)}
                                onChange={e =>
                                  handleCloseTimeChange(day, index, e)
                                }
                                disabled={!isOpen[day]}
                              />
                            </Col>
                          </Row>
                        </td>

                        <td className="align-middle">
                          {index === 0 && (
                            <Button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => handleAddRow(day)}
                              aria-label={`Add row for ${day}`}
                            >
                              +
                            </Button>
                          )}

                          {index > 0 && (
                            <Button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveRow(day, index)}
                              aria-label={`Remove row for ${day}`}
                            >
                              -
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CampaignHours.defaultProps = {
  updatedCampaignHours: () => {}
};

CampaignHours.propTypes = {
  updatedCampaignHours: PropTypes.func.isRequired
};

export default CampaignHours;
