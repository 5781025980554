import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Badge, Row, Col, Spinner, Dropdown } from 'react-bootstrap';
import CountUp from 'react-countup';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CampaignLeadTblHeader from 'components/tables/campaign-leads/CampaignLeadsTblHeader';
import CardDropdown from 'components/common/CardDropdown';

import { viewCampaign } from 'helpers/api/Campaign';
import { viewCampaignLeads } from 'helpers/api/Campaign-Leads';

import PageHeader from 'components/common/PageHeader';

function CampaignDetail() {
  const [campaign, setCampaign] = useState({});
  const [campaignLeads, setCampaignLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const campaign_id = location.pathname.split('/').pop();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await viewCampaign(campaign_id);
        setCampaign(result.data);
        setLoading(false);
        console.log('campaign', campaign);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [campaign_id]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await viewCampaignLeads(campaign_id);
      setCampaignLeads(result.data.leads);
      console.log(result.data);
    };
    fetchData();
  }, [campaign_id]);

  const columns = [
    {
      accessor: 'lead_id',
      Header: 'Name',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original;
        return (
          <>
            <h6>
              <span>{`${first_name} ${last_name}`}</span>
            </h6>
          </>
        );
      }
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { phone_number } = rowData.row.original;
        return (
          <>
            <span>{phone_number}</span>
          </>
        );
      }
    },
    {
      accessor: 'city',
      Header: 'City',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { city } = rowData.row.original;
        return (
          <>
            <span>{city}</span>
          </>
        );
      }
    },
    {
      accessor: 'state',
      Header: 'State',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { state } = rowData.row.original;
        return (
          <>
            <span>{state}</span>
          </>
        );
      }
    },
    {
      accessor: 'dnc',
      Header: 'DNC',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { dnc } = rowData.row.original;
        return (
          <>
            <span>{dnc ? 'Yes' : 'No'}</span>
          </>
        );
      }
    },
    {
      accessor: 'timezone',
      Header: 'Timezone',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { timezone } = rowData.row.original;
        return (
          <>
            <span>{timezone}</span>
          </>
        );
      }
    },
    {
      accessor: 'none',
      Header: 'Actions',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { lead_id } = rowData.row.original;

        const handleEdit = () => {
          console.log('Edit' + lead_id);
        };

        const handleArchive = () => {
          console.log('Archive' + lead_id);
        };

        const handleDelete = () => {
          console.log('Delete' + lead_id);
        };
        return (
          <>
            <CardDropdown drop="start">
              <div className="py-2">
                <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
                <Dropdown.Item onClick={handleArchive}>Archive</Dropdown.Item>
                <Dropdown.Divider as="div" />
                <Dropdown.Item onClick={handleDelete} className="text-danger">
                  Delete
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Row className="g-3 mb-3">
        <Col sm={6} xl={6} xxl={6}>
          <PageHeader
            title="Campaign Details & Statistics"
            titleTag="h5"
            className="mb-3"
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6} xl={6} xxl={6}>
          <span>Campaign Name:</span>{' '}
          <span className="text-primary">{campaign.campaign_name}</span> <br />
          <span>Campaign ID:</span>{' '}
          <span className="text-primary">{campaign.campaign_id}</span>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>Archived Status</Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <>
                  {campaign.archived ? (
                    <h5>
                      <Badge bg="danger">Archived</Badge>
                    </h5>
                  ) : (
                    <h5>
                      <Badge bg="success">Active</Badge>
                    </h5>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>Assigned Agents</Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <h5>
                  <CountUp
                    start={0}
                    end={campaign.assigned_agents}
                    duration={2.75}
                    separator=","
                    delay={0}
                  />
                </h5>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>Campaign Leads</Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <h5>
                  <CountUp
                    start={0}
                    end={campaign.total_leads}
                    duration={2.75}
                    separator=","
                    delay={0}
                  />
                </h5>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>Campaign Status</Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <>
                  {campaign.status === 'ACTIVE' ? (
                    <h5>
                      <Badge bg="success">Active</Badge>
                    </h5>
                  ) : campaign.status === 'PENDING' ? (
                    <h5>
                      <Badge bg="warning">Pending</Badge>
                    </h5>
                  ) : (
                    <h5>
                      <Badge bg="danger">Inactive</Badge>
                    </h5>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>
              Avg O/B Calls
              <small className="text-muted">{' p/h '}</small>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <h5>
                  <CountUp
                    start={0}
                    end={campaign.outbound_calls_per_hour}
                    duration={2.75}
                    separator=","
                    delay={0}
                  />
                </h5>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={2} xl={2} xxl={2}>
          <Card className="mb-3">
            <Card.Header>
              Avg SMS Sent
              <small className="text-muted">{' p/h '}</small>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <h5>
                  <span className="text-dark">
                    <CountUp
                      start={0}
                      end={campaign.outbound_sms_per_hour}
                      duration={2.75}
                      separator=","
                      delay={0}
                    />
                  </span>
                </h5>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6} xl={6} xxl={6}></Col>
        <Col sm={6} xl={6} xxl={6}></Col>
        <Col sm={6} xl={6} xxl={6}></Col>
        <Col sm={6} xl={6} xxl={6}></Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={12} xl={12} xxl={12}>
          <>
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <AdvanceTableWrapper
                columns={columns}
                data={campaignLeads}
                selection
                sortable
                pagination
                perPage={5}
                searchable
                responsive
                filterable
                rowCount={campaignLeads.length}
              >
                <Card className="mb-3">
                  <Card.Header>
                    <CampaignLeadTblHeader table campaignId={campaign_id} />
                  </Card.Header>
                  <Card.Body className="p-0">
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="btn-reveal-trigger text-nowrap align-middle"
                      tableProps={{
                        size: 'sm',
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <AdvanceTableFooter
                      table
                      rowCount={campaignLeads.length}
                      navButtons
                      rowInfo
                    />
                  </Card.Footer>
                </Card>
              </AdvanceTableWrapper>
            )}
          </>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6} xl={6} xxl={6}></Col>
        <Col sm={6} xl={6} xxl={6}></Col>
        <Col sm={6} xl={6} xxl={6}></Col>
      </Row>
    </>
  );
}

export default CampaignDetail;
