import React, { memo } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from '../nodes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SmsNode = () => {
  SmsNode.propTypes = {
    data: PropTypes.object.isRequired
  };

  return (
    <>
      <div className={cx(styles.nodeWrapper)}>
        <div className={cx(styles.nodeTitleBar)}>
          <div>
            <FontAwesomeIcon icon="comment" />
          </div>
          <div className={cx(styles.nodeTitle)}>SMS Lead</div>
        </div>
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="dispositions-tooltip">{'Dispositions'}</Tooltip>}
      >
        <Handle
          id="disposition"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '70px' }}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="data-tooltip">{'Data'}</Tooltip>}
      >
        <Handle
          id="data"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '90px' }}
        />
      </OverlayTrigger>
    </>
  );
};

export default memo(SmsNode);
