import React from 'react';
import LogoutContent from 'components/authentication/LogoutContent';

import AuthCardLayout from 'layouts/AuthCardLayout';

const Logout = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Click return to login. <br />
          To sign in to your account.
        </p>
      }
    >
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
