// noinspection JSValidateTypes

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row, InputGroup, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useAuth from 'hooks/useAuth';
import { login } from 'helpers/api/User';
import { loginSchema } from 'schemas/loginSchema';

const LoginForm = ({ hasLabel }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  //Form State
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  // Handlers
  const { isLoggedIn, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const onSubmit = async data => {
    if (isLoggedIn) {
      navigate('/dashboard');

      return;
    }

    setIsLoading(true);

    try {
      const authResponse = await login(data);
      if (authResponse.status === 200) {
        handleAuthResponse(authResponse);
      } else if (authResponse.status === 401) {
        setErrorMessage('Incorrect email or password');
        console.log(authResponse);
      } else if (authResponse.data?.message === 'You are already logged in!') {
        toast.error('Already logged in redirecting to dashboard.', {
          autoClose: 3000,
          role: 'alert',
          onClose: () => {
            navigate('/dashboard');
          }
        });
        console.log(authResponse);
      } else {
        setErrorMessage('An unknown here error occurred');
        console.log(authResponse);
      }
    } catch (error) {
      setErrorMessage('An unknown error occurred');
    }

    setIsLoading(false);
  };

  const handleAuthResponse = response => {
    if (response.status === 200) {
      const userData = {
        email: response?.data?.user?.email,
        first_name: response?.data?.user?.first_name,
        last_name: response?.data?.user?.last_name,
        role: response?.data?.user?.role,
        id: response?.data?.user?.id
      };

      setAuth({
        isLoggedIn: true,
        roles: [response?.data?.user?.role],
        user: userData
      });

      saveUserDataToLocalStorage('va_user_traits', userData);
      navigateBasedOnRole(response?.data?.user?.role);
      showToast('Logged in successfully', 'success');
    } else {
      const errorMessages = {
        400: 'You are already logged in',
        401: 'Incorrect email or password',
        403: 'You are not authorized to access this resource',
        500: 'Something went wrong'
      };

      setErrorMessage(
        errorMessages[response.status] || 'An unknown error occurred'
      );
    }
  };

  // Utility function to save user data to localStorage
  const saveUserDataToLocalStorage = (key, userData) => {
    try {
      localStorage.setItem(key, JSON.stringify(userData, null, 2));
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  };

  const navigateBasedOnRole = role => {
    const routes = {
      SUPER_ADMIN: '/dashboard',
      ORG_OWNER: '/dashboard',
      ORG_MEMBER: '/dashboard',
      USER: '/org/register',
      DEMO: '/demo',
      default: from
    };

    navigate(routes[role] || routes.default, { replace: true });
  };

  const showToast = (message, type) => {
    toast[type](message, {
      autoClose: 3000,
      role: 'alert'
    });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label htmlFor="email">Email</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email' : ''}
            type="email"
            name="email"
            id="email"
            {...register('email', { required: true })}
            isInvalid={errors.email}
            autoComplete="off"
            aria-label="Email"
            aria-describedby="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email?.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label htmlFor="password">Password</Form.Label>}
          <InputGroup>
            <Form.Control
              placeholder={!hasLabel ? 'Password' : ''}
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              {...register('password', { required: true })}
              isInvalid={errors.password}
              autoComplete="off"
              aria-label="Password"
              aria-describedby="password"
            />
            {errors.password ? (
              <Form.Control.Feedback type="invalid">
                {errors.password?.message}
              </Form.Control.Feedback>
            ) : (
              <InputGroup.Text
                className="cursor-pointer"
                id="password-toggle"
                onClick={togglePassword}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  size="sm"
                />
              </InputGroup.Text>
            )}
          </InputGroup>
        </Form.Group>
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link className="fs--1 mb-0 text-dark" to={`/forgot-password`}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Button
          type="submit"
          role="button"
          name="login"
          variant="primary"
          className="mt-3 w-100"
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner animation="border" variant="dark" size="sm" />
          ) : (
            'Login'
          )}
        </Button>

        <div className="text-center mt-3">
          <small className="text-danger">{errorMessage}</small>
        </div>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
