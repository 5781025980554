import axiosWithCreds from '../../axios/axios';
export const viewOrgLeads = async () => {
  try {
    const response = await axiosWithCreds.get(`leads`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const viewOrgLead = async lead_id => {
  try {
    const response = await axiosWithCreds.get(`leads/${lead_id}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response.data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const appendOrgLeadTag = async (orgLeadId, tag, data) => {
  try {
    const response = await axiosWithCreds.put(
      `leads/${orgLeadId}/tags/${tag}`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export const deleteOrgLeadTag = async (orgLeadId, tag) => {
  try {
    const response = await axiosWithCreds.delete(
      `leads/${orgLeadId}/tags/${tag}`
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export const createOrgLead = async data => {
  try {
    const response = await axiosWithCreds.post(`leads`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const deleteOrgLead = async orgLeadId => {
  try {
    const response = await axiosWithCreds.delete(`leads/${orgLeadId}`);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const uploadOrgLeads = async data => {
  try {
    const response = await axiosWithCreds.put(`leads`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewLeadCallRecords = async orgLeadId => {
  try {
    const response = await axiosWithCreds.get(`leads/${orgLeadId}/calls`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewLeadTextRecords = async orgLeadId => {
  try {
    const response = await axiosWithCreds.get(`leads/${orgLeadId}/texts`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
