import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller } from 'react-hook-form';

const WorkingHoursTable = ({ control }) => {
  const [running_hours, setRunning_hours] = useState({
    sunday: [{ open: '', close: '' }],
    monday: [{ open: '', close: '' }],
    tuesday: [{ open: '', close: '' }],
    wednesday: [{ open: '', close: '' }],
    thursday: [{ open: '', close: '' }],
    friday: [{ open: '', close: '' }],
    saturday: [{ open: '', close: '' }]
  });

  const handleAddRow = day => {
    const values = [...running_hours[day]];
    values.push({ open: '', close: '' });
    setRunning_hours({ ...running_hours, [day]: values });
  };

  const handleRemoveRow = (day, index) => {
    const values = [...running_hours[day]];
    values.splice(index, 1);
    setRunning_hours({ ...running_hours, [day]: values });
  };

  const handleInputChange = (e, day, index) => {
    const { name, value } = e.target;
    const values = [...running_hours[day]];
    values[index][name] = value;
    setRunning_hours({ ...running_hours, [day]: values });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Working Hours</Form.Label>
            <Table responsive>
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Open</th>
                  <th>Close</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(running_hours).map((day, idx) => (
                  <React.Fragment key={idx}>
                    {running_hours[day].map((item, index) => (
                      <tr key={index}>
                        {index === 0 && (
                          <td rowSpan={running_hours[day].length}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </td>
                        )}
                        <td>
                          <Controller
                            name={`running_hours.${day}[${index}].open`}
                            control={control}
                            defaultValue={item.open}
                            render={({ field }) => (
                              <Form.Control
                                type="time"
                                name="open"
                                value={item.open}
                                onChange={e => handleInputChange(e, day, index)}
                                {...field}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`running_hours.${day}[${index}].close`}
                            control={control}
                            defaultValue={item.close}
                            render={({ field }) => (
                              <Form.Control
                                type="time"
                                name="close"
                                value={item.close}
                                onChange={e => handleInputChange(e, day, index)}
                                {...field}
                              />
                            )}
                          />
                        </td>
                        <td>
                          {index === 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => handleAddRow(day)}
                            >
                              <FontAwesomeIcon icon={faCirclePlus} />
                            </button>
                          )}
                          {index > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveRow(day, index)}
                            >
                              <FontAwesomeIcon icon={faCircleMinus} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

WorkingHoursTable.propTypes = {
  control: PropTypes.object.isRequired
};

export default WorkingHoursTable;
