import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Navigate } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import RequireAuth from './RequireAuth';
import MainLayout from '../layouts/MainLayout';

// -- Restricted Routes --

// -- Authentication Pages --
import Login from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import ForgotPassword from 'components/authentication/card/ForgotPassword';
import PasswordReset from 'components/authentication/card/PasswordReset';
import LockScreen from 'components/authentication/card/LockScreen';
import ConfirmMail from 'components/authentication/card/ConfirmMail';
import AccountVerification from 'components/pages/miscellaneous/account/AccountVerification';

// -- Admin Pages --
import PromptTool from 'components/toolbox/prompts/PromptTool';

// -- Dashboard Pages --
import Dashboard from 'components/dashboards/default';
import Payment from 'components/stripe/Payment';
import Completion from 'components/stripe/Completion';
// import Subscription from 'components/stripe/Subscription';

// -- Destinations --
import Destinations from 'components/tables/destinations/Destinations';

// -- Campaign Wizard --
import CampaignForm from 'components/forms/CampaignForm';
import CampaignList from 'components/campaign-list/CampaignList';
import CampaignDetails from 'components/pages/campaign/CampaignDetails';
import NewCampaign from 'components/wizard/campaign/NewCampaign';

// -- Leads --
import LeadTable from 'components/tables/lead-table/LeadTable';
import LeadDetails from 'components/pages/leads/LeadDetails';
// import CsvTable from 'components/wizard/campaign/UploadLeads';
import LeadUploader from 'components/upload/LeadUploader';
import DemoLeadForm from 'components/upload/DemoLeadForm';

//-- Numbers --
import NumbersTable from 'components/tables/numbers/NumbersTable';

// -- Reports --
// import CallsReport from 'components/reports/CallsReport';
// import SMSReport from 'components/reports/SMSReport';
// import DailyReport from 'components/reports/DailyReport';
import Analytics from 'components/reports/analytics';

// -- Profile --
import Profile from 'components/pages/user/profile/Profile';

// -- Registration --
import Registration from 'components/authentication/card/Registration';
import OrgRegForm from 'components/forms/OrgRegForm';

// -- Webhooks --
import WebhooksInterface from 'components/webhooks/Webhooks';

// -- Error Pages --
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Error403 from 'components/errors/Error403';
import Unauthorized from 'components/errors/Unauthorized';
import UnderConstruction from 'components/errors/UnderConstruction';

const ROLES = {
  NONE: 'NONE',
  USER: 'USER',
  MEMBER: 'ORG_MEMBER',
  OWNER: 'ORG_OWNER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  SALES: 'SALES_REP',
  PROMPT: 'PROMPT_ENGINEER',
  SYSTEM: 'SYSTEM_ADMIN',
  DEMO: 'DEMO'
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Redirect root to /login */}
      <Route path="/" element={<Navigate to="/login" replace />} />

      {/* Public Facing Routes */}
      <Route path="/campaign/wizard" element={<CampaignForm />} />

      <Route
        path="/login"
        element={
          <PublicRoutes>
            <Login />
          </PublicRoutes>
        }
      />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password-reset/:token" element={<PasswordReset />} />
      <Route path="/lock-screen" element={<LockScreen />} />
      <Route path="/confirm-email" element={<ConfirmMail />} />
      <Route path="/error-404" element={<Error404 />} />
      <Route path="/error-500" element={<Error500 />} />
      <Route path="/error-403" element={<Error403 />} />
      <Route path="/under-construction" element={<UnderConstruction />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/registration" element={<Registration />} />
      <Route
        path="/account-verified/:token"
        element={<AccountVerification />}
      />
      <Route path="/org/register" element={<OrgRegForm />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/completion" element={<Completion />} />
      {/* <Route path="/subscription" element={<Subscription />} /> */}

      {/* <Route element={<RequireAuth allowedRoles={[ROLES.USER]} />}></Route> */}

      {/* Private Routes */}

      {/* Restricted Routes */}
      <Route
        element={
          <RequireAuth
            allowedRoles={[
              ROLES.SUPER_ADMIN,
              ROLES.OWNER,
              ROLES.MEMBER,
              ROLES.USER
            ]}
          />
        }
      >
        <Route path="/" element={<MainLayout />}>
          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Leads Routes */}
          <Route path="/leads" element={<LeadTable />} />
          <Route path="/lead/details/:lead_id" element={<LeadDetails />} />
          {/* <Route path="/lead/upload" element={<CsvTable />} /> */}

          {/* Campaign Routes */}
          <Route path="/campaigns/list" element={<CampaignList />} />
          <Route path="/campaign/new" element={<NewCampaign />} />

          {/* Destinations Routes */}
          <Route path="/destinations" element={<Destinations />} />

          <Route
            path="/campaign/details/:campaign_id"
            element={<CampaignDetails />}
          />
          <Route
            path="/campaign/lead/upload/:campaign_id"
            element={<LeadUploader />}
          />
          {/* Report Routes */}
          <Route path="/reports/examples" element={<Analytics />} />
          {/* <Route path="/reports/sms" element={<SMSReport />} />
          <Route path="/reports/daily" element={<DailyReport />} /> */}
          {/* Numbers Route*/}
          <Route path="/numbers" element={<NumbersTable />} />
          {/* Pages */}
          <Route path="user/profile" element={<Profile />} />
          {/* Webhooks */}
          <Route path="tools/webhooks" element={<WebhooksInterface />} />
          {/* Utilities */}
          <Route path="/tools/prompts" element={<PromptTool />} />
        </Route>
      </Route>

      <Route element={<RequireAuth allowedRoles={[ROLES.DEMO, ROLES.OWNER]} />}>
        <Route path="/demo" element={<DemoLeadForm />} />
      </Route>
      {/* Fallback for unmatched routes */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

AppRoutes.propTypes = {
  layout: PropTypes.string
};

export default AppRoutes;
