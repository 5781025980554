import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

//Login Card Component
const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
          <div className="text-light">
            Please enter your email and password to login to your account.
          </div>
          <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
            <p className="fs--1 text-white">
              Don't have an account?{' '}
              <Link className="text-white" to="/registration">
                Register Here
              </Link>
            </p>
          </div>
        </div>
      }
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

Login.propTypes = {
  layout: PropTypes.string,
  errorMessage: PropTypes.string
};

export default Login;
