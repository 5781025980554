import React, { createContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  let user = {};
  try {
    user = JSON.parse(localStorage.getItem('va_user_traits')) || {};
  } catch (error) {
    console.error('Error parsing user traits', error);
  }

  const initialAuthState = {
    isLoggedIn: Cookies.get('isLoggedIn') === 'true',
    roles: Cookies.get('roles')?.split(',') || [],
    user
  };

  const [auth, setAuth] = useState(initialAuthState);

  useEffect(() => {
    Cookies.set('isLoggedIn', auth.isLoggedIn, {
      domain: '.vagents.com',
      expires: 7,
      secure: true,
      sameSite: 'Lax'
    });
    Cookies.set('roles', auth.roles, {
      domain: '.vagents.com',
      expires: 7,
      secure: true,
      sameSite: 'Lax'
    });

    localStorage.setItem('va_user_traits', JSON.stringify(auth.user));
  }, [auth]);

  const authValue = useMemo(() => ({ auth, setAuth }), [auth, setAuth]);

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
