import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { searchPurchasableNumbers } from 'helpers/api/PhoneNumbers';

//List of states
import { states } from 'data/states';

// Updated orderByOptions to include "asc" and "desc" values
const orderByOptions = [
  { value: 'state,asc', label: 'State (A-Z)' },
  { value: 'state,desc', label: 'State (Z-A)' },
  { value: 'number,asc', label: 'Number (A-Z)' },
  { value: 'number,desc', label: 'Number (Z-A)' }
];

// Number validation schema
const numberSchema = yup.object().shape({
  state: yup.string(),
  iso_country: yup.string(),
  number_type: yup.string().required(''),
  order_by: yup.string(),
  limit: yup.number().positive().integer().required(),
  contains: yup.string().matches(/^[0-9]*$/, 'Contains must be a number')
});

const PurchaseNumbersModal = ({ show, onHide }) => {
  const [formData, setFormData] = useState({
    state: '',
    iso_country: '',
    number_type: '',
    order_by: '',
    limit: 0,
    contains: 0
  });
  const [purchasableNumbers, setPurchasableNumbers] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const toggleSelectedNumbers = number => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter(n => n !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleFormChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = async () => {
    try {
      await numberSchema.validate(formData, { abortEarly: false });
      return true; // Form is valid
    } catch (err) {
      const errors = err.inner.reduce((acc, currentError) => {
        acc[currentError.path] = currentError.message;
        return acc;
      }, {});
      setFormErrors(errors);
      return false; // Form is invalid
    }
  };

  const handleSearch = async () => {
    const isValid = await validateForm();
    if (!isValid) return; // Stop the search if the form is invalid

    try {
      const response = await searchPurchasableNumbers(formData);
      setPurchasableNumbers(response);
      setFormErrors({}); // Clear form errors on successful search
    } catch (error) {
      console.error('Error fetching purchasable numbers:', error);
    }
  };

  return (
    <>
      <Container>
        <Modal show={show} onHide={onHide} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Search Purchasable Numbers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="mb-3">
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Select
                      name="state"
                      value={formData.state}
                      onChange={handleFormChange}
                    >
                      <option value="">--Select--</option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Text className="text-danger">
                      {formErrors.state}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Limit</Form.Label>
                    <Form.Control
                      type="number"
                      name="limit"
                      value={formData.limit}
                      onChange={handleFormChange}
                    />

                    <Form.Text className="text-danger">
                      {formErrors.limit}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Number Type</Form.Label>
                    <Form.Select
                      name="number_type"
                      value={formData.number_type}
                      onChange={handleFormChange}
                    >
                      <option value="">--Select--</option>
                      <option value="longcode">Longcode</option>
                      <option value="tollfree">Tollfree</option>
                    </Form.Select>

                    <Form.Text className="text-danger">
                      {formErrors.number_type}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Order By</Form.Label>
                    <Form.Select
                      name="order_by"
                      value={formData.order_by}
                      onChange={handleFormChange}
                    >
                      <option value="">--Select--</option>
                      {orderByOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <Form.Text className="text-danger">
                      {formErrors.order_by}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="iso_country"
                      value={formData.iso_country}
                      onChange={handleFormChange}
                    />

                    <Form.Text className="text-danger">
                      {formErrors.iso_country}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Contains</Form.Label>
                    <Form.Control
                      type="text"
                      name="contains"
                      value={formData.contains}
                      onChange={handleFormChange}
                      pattern="[0-9]*"
                    />

                    <Form.Text className="text-danger">
                      {formErrors.contains}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col></Col>
              </Row>
            </Form>
            <Divider />
            <ul>
              <Row className="mb-3">
                {purchasableNumbers.map((number, index) => (
                  <Col md={2} key={index} className="mb-3">
                    {' '}
                    <li className="text-dark" style={{ listStyleType: 'none' }}>
                      <Form.Check
                        onChange={() => toggleSelectedNumbers(number)}
                        type="checkbox" // Changed from "radio" to "checkbox"
                        label={number.number}
                        checked={selectedNumbers.includes(number)}
                      />
                    </li>
                  </Col>
                ))}
              </Row>
            </ul>
            <Divider />
            <ul>
              {selectedNumbers.map((number, index) => (
                <li key={index}>{number.number}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            {selectedNumbers.length > 0 ? ( // Updated condition to check if any numbers are selected
              <Button variant="primary">Purchase</Button>
            ) : (
              <div>
                <Button variant="secondary" onClick={onHide}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSearch}>
                  Search
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

PurchaseNumbersModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default PurchaseNumbersModal;
