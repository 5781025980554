import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import NewDestinationModal from './NewDestinationModal';

const DestinationTableHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter,
  refreshDestinations
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    refreshDestinations();
  };
  return (
    <>
      <Row className="flex-between-center flex-column flex-sm-row">
        <Col sm="auto" lg={3} className="d-flex align-items-center pe-0">
          <AdvanceTableSearchBox
            className="ms-2"
            placeholder="Search Destinations"
            data-testid="search-destinations"
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>

        <Col sm="auto" className="">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="campaign-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="mx-2"
                onClick={handleShowModal}
              >
                <span className="ms-1">New</span>
              </IconButton>

              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
              >
                <span className="ms-1">Export</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
      <NewDestinationModal
        show={showModal}
        onHide={handleModalClose}
        onSuccess={handleModalClose}
        refreshDestinations={refreshDestinations}
      />
    </>
  );
};

DestinationTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refreshDestinations: PropTypes.func,
  showModal: PropTypes.bool
};

export default DestinationTableHeader;
