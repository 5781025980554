import { useEffect } from 'react';

export const useAutoResizeTextarea = (ref, value, modalVisible) => {
  useEffect(() => {
    if (modalVisible) {
      ref.current.style.height = 'auto';
      ref.current.style.height = ref.current.scrollHeight + 'px';
    }
  }, [value, modalVisible]);
};
