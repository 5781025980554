import axiosWithCreds from '../../axios/axios';
export const viewCampaigns = async () => {
  try {
    const response = await axiosWithCreds.get(`campaign`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const createCampaign = async data => {
  try {
    const response = await axiosWithCreds.post(`campaign`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const viewCampaign = async campaign_id => {
  try {
    const response = await axiosWithCreds.get(`campaign/${campaign_id}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};
export const archiveCampaign = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.post(
      `campaign/${campaignId}/archive`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export const cloneCampaign = async (campaignId, data) => {
  try {
    const response = await axiosWithCreds.post(
      `campaign/${campaignId}/clone`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
export const deleteCampaign = async campaignId => {
  try {
    const response = await axiosWithCreds.delete(`campaign/${campaignId}`);
    console.log(response.data);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const assignDestination = async (campaignId, destinationId, data) => {
  try {
    const response = await axiosWithCreds.put(
      `campaign/${campaignId}/destination/${destinationId}`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
