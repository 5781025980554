import React, { useState, useEffect } from 'react';
import {
  Card,
  Dropdown,
  Badge,
  Row,
  Modal,
  Tabs,
  Tab,
  Form,
  Container,
  Col,
  Button,
  ListGroup,
  Spinner,
  Alert
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faPhoneSlash,
  faCommentSms,
  faStickyNote
} from '@fortawesome/free-solid-svg-icons';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import LeadTableHeader from './LeadTableHeader';

import { viewOrgLead } from 'helpers/api/Org-Leads';

import { viewOrgLeads } from 'helpers/api/Custom';

import PageHeader from 'components/common/PageHeader';
import { leadDialog } from 'data/leadDialog';

//Columns for the table
const columns = [
  {
    accessor: 'first_name',
    Header: 'First Name / Last Name',
    headerProps: { className: 'pe-1 sticky-column' },
    cellProps: {
      className: 'fw-semi-bold'
    },
    Cell: rowData => {
      const { first_name, last_name, lead_id } = rowData.row.original;
      return (
        <>
          <Link
            to={`/lead/details/${lead_id}`}
            className="text-dark fw-bold fs-0"
          >{`${first_name} ${last_name}`}</Link>
        </>
      );
    }
  },
  {
    accessor: 'phone_number',
    Header: 'Number',
    headerProps: { className: 'pe-7' },
    cellProps: {
      className: 'fe-semi-bold'
    },
    Cell: rowData => {
      const { phone_number } = rowData.row.original;
      return (
        <div className="text-dark">
          <span>{phone_number}</span>
        </div>
      );
    }
  },

  {
    accessor: 'dnc',
    Header: 'DNC',

    headerProps: { className: 'text-center' },
    cellProps: {
      className: 'fs-0',
      style: {
        width: '25px'
      }
    },
    Cell: rowData => {
      const { dnc } = rowData.row.original;
      return (
        <Badge pill bg={dnc ? 'danger' : 'success'} className="d-block">
          {dnc}
          <FontAwesomeIcon
            icon={dnc ? faPhoneSlash : faPhone}
            transform="shrink-3"
            className="ms-1"
          />
        </Badge>
      );
    }
  },
  {
    accessor: 'timezone',
    Header: 'Timezone',
    headerProps: {
      className: 'text-end'
    },
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { timezone } = rowData.row.original;
      return (
        <div className="text-dark">
          <span>{timezone}</span>
        </div>
      );
    }
  },
  {
    accessor: 'lead_id',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end py-2'
    },
    Cell: rowData => {
      const [show, setShow] = useState(false);
      const [leadData, setLeadData] = useState({});
      const [edit, setEdit] = useState(false);

      const handleClose = () => {
        setShow(false);
        setEdit(false);
      };
      const handleShow = () => setShow(true);

      const toggleEdit = () => setEdit(!edit);

      const { lead_id } = rowData.row.original;

      useEffect(() => {
        if (show) {
          const getByLeadId = async () => {
            const response = await viewOrgLead(lead_id);
            console.log(response);
            setLeadData(response.data);
          };
          getByLeadId();
          console.log();
        }
      }, [show, lead_id]);

      return (
        <>
          <CardDropdown drop="start">
            <div className="py-2">
              <Dropdown.Item href="#!" onClick={handleShow}>
                Details
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item href="#!" className="text-warning">
                Archive
              </Dropdown.Item>
              <Dropdown.Item href="#!" className="text-danger">
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {leadData.first_name} {leadData.last_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                defaultActiveKey="lead"
                id="lead-details-tab"
                className="mb-3"
                fill
                justify
              >
                <Tab eventKey="lead" title="Lead">
                  {edit ? (
                    <Container>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="leadFirstName"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              aria-label="First Name Input"
                              placeholder="Enter First Name"
                              value={leadData.first_name}
                              readOnly
                              plaintext
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="leadLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              aria-label="Last Name Input"
                              placeholder="Enter Last Name"
                              value={leadData.last_name}
                              readOnly
                              plaintext
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="leadPhoneNumber"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              aria-label="Phone Number Input"
                              placeholder="Enter Phone Number"
                              value={leadData.phone_number}
                              onChange={e =>
                                setLeadData({
                                  ...leadData,
                                  phone_number: e.target.value
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="leadTimezone">
                            <Form.Label>Timezone</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Timezone"
                              value={leadData.timezone}
                              readOnly
                              plaintext
                              aria-label="Timezone"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="leadDNCStatus"
                          >
                            <Form.Label>DNC</Form.Label>
                            <Form.Select
                              aria-label="DNC Select Status"
                              value={leadData.dnc}
                              onChange={e =>
                                setLeadData({
                                  ...leadData,
                                  dnc: e.target.value
                                })
                              }
                            >
                              <option value="">
                                {leadData.dnc ? 'DNC' : 'Not DNC'}
                              </option>
                              <option value={true}>DNC</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="mb-3" controlId="leadNotes">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              aria-label="Lead Notes"
                              placeholder="Enter Notes"
                              value={leadData.notes}
                              onChange={e =>
                                setLeadData({
                                  ...leadData,
                                  notes: e.target.value
                                })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  ) : (
                    <Container>
                      <Row className="mb-3">
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <span className="fw-bold">First Name:</span>{' '}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>{leadData.first_name}</Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <span className="fw-bold">Last Name:</span>{' '}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>{leadData.last_name}</Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <span className="fw-bold">Phone Number:</span>{' '}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>{leadData.phone_number}</Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <span className="fw-bold">Timezone:</span>{' '}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>{leadData.timezone}</Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <span className="fw-bold">DNC:</span>{' '}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                {leadData.dnc ? 'DNC' : 'Not DNC'}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </Tab>
                <Tab eventKey="tasks" title="History">
                  {leadDialog.map((dialog, index) => (
                    <ListGroup as="ol" aria-label="Lead History" key={index}>
                      <ListGroup.Item
                        as="li"
                        aria-label="Lead Dialog Item"
                        className="d=flex"
                      >
                        <div className="d-flex justify-content-between  w-100">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">
                              {dialog.contact_method === 'sms' ? (
                                <span>SMS</span>
                              ) : (
                                <span>Call</span>
                              )}{' '}
                            </div>
                            {dialog.contact_status}
                            <br />
                            {dialog.contact_time}
                            <br />
                            <span className="fw-bold">
                              Request DNC:{' '}
                              {dialog.call_dnc === 'yes' ? 'Yes' : 'No'}
                            </span>
                            <br />

                            {dialog.tags
                              ? dialog.tags.map((tag, index) => (
                                  <Badge
                                    key={index}
                                    bg={
                                      dialog.call_dnc === 'yes'
                                        ? 'danger'
                                        : 'primary'
                                    }
                                    className="me-1"
                                  >
                                    {tag}
                                  </Badge>
                                ))
                              : ' '}
                          </div>

                          <div className="p-2 align-self-center">
                            <FontAwesomeIcon
                              icon={
                                dialog.contact_method === 'sms'
                                  ? faCommentSms
                                  : faPhone
                              }
                              transform="grow-16"
                            />
                          </div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
                </Tab>
                <Tab eventKey="Transcriptions" title="Transcriptions">
                  {leadDialog.map((dialog, dialogIndex) => (
                    <ListGroup
                      as="ol"
                      aria-label="Lead Transcriptions"
                      key={dialogIndex}
                    >
                      <ListGroup.Item as="li" className="fw-bold">
                        Contact on {dialog.contact_date} at{' '}
                        {dialog.contact_time}
                      </ListGroup.Item>
                      {dialog.contact_transcription.map(
                        (transcription, index) => (
                          <ListGroup.Item as="li" key={index}>
                            <div>
                              <strong>
                                {transcription.speaker === 'agent'
                                  ? 'Agent'
                                  : 'Lead'}
                                :
                              </strong>{' '}
                              {transcription.text}
                            </div>
                          </ListGroup.Item>
                        )
                      )}
                      <hr />
                    </ListGroup>
                  ))}
                </Tab>

                <Tab eventKey="notes" title="Notes">
                  {leadDialog.map((dialog, index) => (
                    <ListGroup as="ol" aria-label="Lead Notes" key={index}>
                      <ListGroup.Item
                        as="li"
                        aria-label="Lead Notes Item"
                        className="d=flex"
                      >
                        <div className="d-flex justify-content-between  w-100">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold">
                              {dialog.call_notes ? (
                                <span>Call Notes</span>
                              ) : (
                                <span>SMS Notes</span>
                              )}{' '}
                            </div>
                            <br />
                            {dialog.call_notes ? dialog.call_notes : ''}

                            {dialog.sms_notes ? dialog.sms_notes : ''}
                          </div>
                          <div className="p-2 align-self-center">
                            <FontAwesomeIcon
                              icon={faStickyNote}
                              transform="grow-16"
                            />
                          </div>
                        </div>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={toggleEdit}
                aria-label="Edit Button"
              >
                {edit ? 'Cancel' : 'Edit'}
              </Button>
              <Button
                variant="secondary"
                onClick={handleClose}
                aria-label="Close Button"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }
];

const LeadTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchLeads = async () => {
      setIsLoading(true);
      const res = await viewOrgLeads({
        page
      });
      console.log(res.leads);
      setLeads(res.leads);
      setIsLoading(false);
    };
    fetchLeads();
  }, [page]);

  const handlePageChange = async page => {
    setPage(page);
    const res = await viewOrgLeads({
      page
    });
    setLeads(res.data.leads);
  };

  return (
    <>
      {isLoading ? (
        <div
          className="text-center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Spinner animation="border" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={leads}
          selection
          sortable
          pagination
          perPage={50}
          searchable
          responsive
          filterable
          footer
        >
          <Row className="mb-3 mt-3">
            <PageHeader
              title="Leads List"
              breadCrumbItems={[
                {
                  path: '/leads',
                  active: true,
                  label: 'Leads'
                }
              ]}
            />
          </Row>
          <Card>
            <Card.Header>
              <LeadTableHeader table />
            </Card.Header>
            <Card.Body className="p-0">
              {leads.length > 0 ? (
                <div style={{ overflowY: 'auto', height: '70vh' }}>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger text-nowrap align-middle"
                    tableProps={{
                      size: 'sm',
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </div>
              ) : (
                <Alert variant="info" className="text-center">
                  No leads found click on the Add Lead button to create a new
                  lead
                </Alert>
              )}
            </Card.Body>
            <Card.Footer>
              <AdvanceTableFooter
                table
                rowCount={leads.length}
                pagination
                pagesCount={leads.length / 50}
                navButtons
                rowInfo
                onPageChange={handlePageChange}
              />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default LeadTable;
