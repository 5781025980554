import React, { memo } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from '../nodes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UnpackDispoNode = ({ data }) => {
  UnpackDispoNode.propTypes = {
    data: PropTypes.object.isRequired
  };

  return (
    <>
      <div
        className={cx(styles.node + ' ' + styles.nodeUtility)}
        style={{ minWidth: data.inputs.call.choices.length * 20.5 + 'px' }}
      >
        <div className={cx(styles.nodeWrapper)}>
          <div className={cx(styles.nodeTitleBar)}>
            <div>
              <FontAwesomeIcon icon="filter" />
            </div>
            <div className={cx(styles.nodeTitle)}>Unpack Disposition</div>
          </div>
        </div>
        {data.dispositions.choices.map((choice, index) => {
          return (
            <OverlayTrigger
              key={index}
              placement="bottom"
              overlay={
                <Tooltip id={'disposition-tooltip-' + choice}>{choice}</Tooltip>
              }
            >
              <Handle
                key={index}
                type="source"
                position={Position.Left}
                id={choice}
                style={{ left: 10 + index * 20, top: 45 }}
                className={cx(styles.handleUtility)}
              />
            </OverlayTrigger>
          );
        })}
      </div>
    </>
  );
};

export default memo(UnpackDispoNode);
