import axiosWithCreds from '../../axios/axios';
export const viewOrgLeads = async ({
  lead_id = null,
  campaign_id = null,
  first_name = null,
  last_name = null,
  phone_number = null,
  city = null,
  state = null,
  zip_code = null,
  dnc = null,
  tags = [],
  status = null,
  page = 0
} = {}) => {
  try {
    const response = await axiosWithCreds.get('leads', {
      params: {
        lead_id,
        campaign_id,
        first_name,
        last_name,
        phone_number,
        city,
        state,
        zip_code,
        dnc,
        tags,
        status,
        page
      },
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response.data.total_leads);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const viewCampaigns = async ({
  campaign_id = null,
  name = null,
  status = null,
  page = 0,
  limit = 500
} = {}) => {
  try {
    const response = await axiosWithCreds.get('campaign', {
      params: {
        campaign_id,
        name,
        status,
        page,
        limit
      },
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
