import axiosWithCreds from '../../axios/axios';
export const viewDestinations = async () => {
  try {
    const response = await axiosWithCreds.get(`destinations`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewDestination = async destinationId => {
  try {
    const response = await axiosWithCreds.get(`destinations/${destinationId}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const createDestination = async data => {
  try {
    const response = await axiosWithCreds.post(`destinations`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateDestination = async (destinationId, data) => {
  try {
    const response = await axiosWithCreds.put(
      `destinations/${destinationId}`,
      data,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteDestination = async destinationId => {
  try {
    const response = await axiosWithCreds.delete(
      `destinations/${destinationId}`,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
