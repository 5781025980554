import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import ProfileBanner from '../ProfileBanner';

const Banner = ({ avatar, coverSrc, title }) => {
  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">Title</h4>
            <h5 className="fs-0 fw-normal">{title}</h5>

            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3"></Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

Banner.propTypes = {
  avatar: PropTypes.string,
  coverSrc: PropTypes.string,
  title: PropTypes.string
};

export default Banner;
