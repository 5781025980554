import React, { useCallback, useState } from 'react';

import {
  Form,
  Accordion,
  Button,
  Card,
  Row,
  Col,
  Modal,
  Spinner
} from 'react-bootstrap';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useNavigate } from 'react-router-dom';
import axiosWithCreds from '../../axios/axios';
import debounce from 'lodash.debounce';

//Schema
import { campaignSchema } from 'schemas/createCampaign';

//Components
import PageHeader from 'components/common/PageHeader';
import FlowBuilder from 'components/flowbuilder/Flow';
import WorkingHoursTable from 'components/tables/hours/WorkingHours';
// import CsvTable from 'components/upload/LeadUploader';

//Data
import { destinationData } from 'data/destinations';

// API Helpers
import { createCampaign } from 'helpers/api/Campaign';
import { createFlow, updateFlow } from 'helpers/api/Campaign-Flow';

// Flow Builder
import { useNodesState, useEdgesState } from 'reactflow';

const defaultNodes = [
  {
    id: 'root',
    type: 'root',
    data: { function: 'outbound', trigger: null, metadata: null, event: null },
    position: { x: 0, y: 0 },
    draggable: true,
    deletable: false
  }
];

const CampaignForm = () => {
  const [show, setShow] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [selectedDestination, setSelectedDestination] = useState(null);

  // Flow Builder
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
  const [edges, setEdges] = useEdgesState([]);
  const campaignIdRef = React.useRef(campaignId);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(campaignSchema)
  });

  // const navigate = useNavigate();

  const onSubmit = async data => {
    const res = await createCampaign(data);

    if (res.status === 200) {
      setCampaignId(res.data.campaign_id);
      campaignIdRef.current = res.data.campaign_id;
      await createFlow(campaignIdRef.current, {
        flow: { nodes: nodes, edges: edges }
      });
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    const allowedTypes = ['text/csv'];
    if (file && allowedTypes.includes(file.type)) {
      setFileType(file.type);
      setFile(file);
      setFileName(file.name);
      setFileError(null);
    } else {
      setFile(null);
      setFileError('Please select a CSV file.');
    }
  };

  const handleLeadUpload = (campaignId, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('fileType', fileType);
    axiosWithCreds.put(`/campaign/${campaignId}/leads`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    setLoading(false);
  };

  const saveFlow = debounce(async (nds, eds) => {
    (async () => {
      if (campaignIdRef.current || campaignIdRef.current !== null) {
        await updateFlow(campaignIdRef.current, {
          flow: { nodes: nds, edges: eds }
        });
      }
    })();
  }, 3000);

  const debouncedSaveFlow = useCallback((nds, eds) => {
    saveFlow(nds, eds);
  }, []);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageHeader
            title="New Campaign"
            breadCrumbItems={[
              {
                path: '/campaign/new',
                active: true
              }
            ]}
          />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <Card.Body>
            <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Campaign Name & Destination</Accordion.Header>
                <Accordion.Body>
                  <Row className="g-2 mb-3 justify-content-center">
                    <Col md={8}>
                      <Form.Group className="mb-3" controlId="section1">
                        <Form.Label>Campaign Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="campaign_name"
                          placeholder="Enter Campaign Name"
                          {...register('campaign_name', {
                            required: 'Campaign name is required.'
                          })}
                          isInvalid={!!errors.campaign_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.campaign_name?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-3 justify-content-center">
                    <Col md={8}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Destination</Form.Label>
                        <Form.Text className="text-muted ">
                          &nbsp; &nbsp;( If you do not see your destination,
                          please enter it in the input below. )
                        </Form.Text>
                        <Form.Select
                          {...register('destination')}
                          onChange={selectedDestination =>
                            setSelectedDestination(
                              selectedDestination.target.value
                            )
                          }
                          isInvalid={errors.destination}
                        >
                          <option>{selectedDestination}</option>
                          {destinationData.map((destination, index) => (
                            <option key={index} value={destination.id}>
                              {destination.name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.destination?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="g-2 mb-3 justify-content-end">
                    <Col md={3}>
                      <Button
                        variant="primary"
                        data-bs-toggle="modal"
                        data-bs-target="#addDestinationModal"
                        className="text-end"
                        onClick={() => setShow(true)}
                      >
                        Add Destination
                      </Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Upload Leads & Preview</Accordion.Header>
                <Accordion.Body>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control
                          type="file"
                          accept=".csv"
                          onChange={handleFileChange}
                        />

                        {file && (
                          <p className="text-success">{file.name} selected</p>
                        )}
                      </Form.Group>

                      {fileError && <p className="text-danger">{fileError}</p>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Button
                        variant="primary"
                        onClick={() => handleLeadUpload(campaignId, file)}
                      >
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                            Uploading...
                          </>
                        ) : (
                          'Upload Leads'
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Hours of Operation</Accordion.Header>
                <Accordion.Body>
                  <WorkingHoursTable control={control} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Flow Builder</Accordion.Header>
                <Accordion.Body>
                  <FlowBuilder
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    onNodesChange={onNodesChange}
                    saveFlow={(nds, eds) => {
                      debouncedSaveFlow(nds, eds);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: '16px' }}
            >
              Submit
            </Button>
          </Card.Body>
        </Card>

        {/* Add Destination Modal */}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Destination</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="section1">
              <Form.Label>Destination Name</Form.Label>
              <Form.Control
                type="text"
                name="destination_name"
                placeholder="Enter Destination Name"
                {...register('destination_name', {
                  required: 'Destination name is required.'
                })}
                isInvalid={!!errors.destination_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.destination_name?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="section1">
              <Form.Label>Destination Number</Form.Label>
              <Form.Control
                type="text"
                name="destination_number"
                placeholder="Enter Destination Number"
                {...register('destination_number', {
                  required: 'Destination number is required.'
                })}
                isInvalid={!!errors.destination_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.destination_number?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="section1">
              <Form.Label>Destination Timezone</Form.Label>
              <Form.Control
                type="text"
                name="destination_timezone"
                placeholder="Enter Destination Timezone"
                {...register('destination_timezone', {
                  required: 'Destination timezone is required.'
                })}
                isInvalid={!!errors.destination_timezone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.destination_timezone?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="section1">
              <Form.Label>Destination Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="destination_notes"
                placeholder="Enter Destination Notes"
                {...register('destination_notes', {
                  required: 'Destination notes is required.'
                })}
                isInvalid={!!errors.destination_notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.destination_notes?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setShow(false)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

CampaignForm.propTypes = {
  campaign: PropTypes.object
};

export default CampaignForm;
