import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

import { createPrompt } from 'helpers/api/Prompts';

const CreatePromptModal = ({ show, onHide }) => {
  const [prompt, setPrompt] = useState('');
  const [name, setName] = useState('');
  const [orgId, setOrgId] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [temp, setTemp] = useState(0);
  const [topP, setTopP] = useState(0);

  const handleFormSubmit = async e => {
    e.preventDefault();
    try {
      const response = await createPrompt({
        name,
        orgId,
        campaignId,
        prompt,
        temp,
        top_p: topP
      });
      console.log(response);
      onHide();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Create Prompt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Enter name"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Org ID</Form.Label>
                <Form.Control
                  type="text"
                  name="Org ID"
                  value={orgId}
                  onChange={e => setOrgId(e.target.value)}
                  placeholder="Enter Org ID"
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Campaign ID</Form.Label>
                <Form.Control
                  type="text"
                  name="Campaign ID"
                  value={campaignId}
                  onChange={e => setCampaignId(e.target.value)}
                  placeholder="Enter Campaign ID"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3} className="mb-3">
              <Form.Group>
                <Form.Label>Temperature</Form.Label>
                <Form.Control
                  type="number"
                  name="temp"
                  step="0.01"
                  value={temp}
                  onChange={e => setTemp(e.target.value)}
                  placeholder="Enter Temperature"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group>
                <Form.Label>Top P</Form.Label>
                <Form.Control
                  type="number"
                  name="topP"
                  step="0.01"
                  value={topP}
                  onChange={e => setTopP(e.target.value)}
                  placeholder="Enter Top P"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Prompt</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="prompt"
                  value={prompt}
                  onChange={e => setPrompt(e.target.value)}
                  placeholder="Enter prompt"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreatePromptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

export default CreatePromptModal;
