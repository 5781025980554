/* eslint-disable no-dupe-keys */
export const leadDialog = [
  {
    contact_method: 'call',
    contact_time: '08:36:03 AM EST',
    contact_date: '2021-09-01',
    contact_status: 'connected',
    contact_duration: '00:05:20',
    contact_transcription: [
      {
        speaker: 'agent',
        text: 'Hello, this is John Doe with ABC Company. Can I speak with John Smith?'
      },
      {
        speaker: 'lead',
        text: 'Yes, this is John Smith.'
      },
      {
        speaker: 'agent',
        text: 'Great, I am calling to let you know about our new product.'
      },
      {
        speaker: 'lead',
        text: 'I am not interested.'
      },
      {
        speaker: 'agent',
        text: 'Ok, thank you for your time.'
      }
    ],
    call_notes: 'John Smith was not interested in our new product.',
    tags: ['not interested', 'do not call'],
    call_dnc: 'yes'
  },
  {
    contact_method: 'sms',
    contact_time: '08:36:03 AM EST',
    contact_date: '2021-20-01',
    contact_status: 'message sent',
    contact_transcription: [
      {
        speaker: 'agent',
        text: 'Hello, this is John Doe with ABC Company. Can I speak with John Smith?'
      },
      {
        speaker: 'lead',
        text: 'Yes, this is John Smith.'
      },
      {
        speaker: 'agent',
        text: 'Great, I am calling to let you know about our new product.'
      },
      {
        speaker: 'lead',
        text: 'I am not interested.'
      },
      {
        speaker: 'agent',
        text: 'Ok, thank you for your time.'
      }
    ],
    sms_notes: 'John Smith said he will call back.',
    tags: ['call back'],
    sms_dnc: 'no'
  }
];
