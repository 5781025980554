import React from 'react';
import { Row, Col } from 'react-bootstrap';

//Components
import TotalActiveAgents from 'components/stats/stats-cards/ActiveAgentsCard';
import SMSStatsCard from 'components/stats/stats-cards/SMSStatsCard';
import ActiveLeadsCard from 'components/stats/stats-cards/ActiveLeadsCard';
import CampaignStatsCard from 'components/stats/stats-cards/CampaignStatsCard';

//Data

import { callsMade } from 'data/dashboard/default';

const StatsCards = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xl={3}>
          <CampaignStatsCard data={callsMade} />
        </Col>
        <Col md={6} xl={3}>
          <ActiveLeadsCard data={callsMade} />
        </Col>
        <Col md={6} xl={3}>
          <TotalActiveAgents />
        </Col>
        <Col md={6} xl={3}>
          <SMSStatsCard data={callsMade} />
        </Col>
      </Row>
    </>
  );
};

export default StatsCards;
