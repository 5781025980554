import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Alert } from 'react-bootstrap';
// import CardDropdown from 'components/common/CardDropdown';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import NumbersTablesHeader from './NumbersTablesHeader';

// helper function
import { viewOrgNumbers } from 'helpers/api/Org-Numbers';

const columns = [
  {
    accessor: 'number',
    Header: 'Number',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const { number } = rowData.row.original;
      return (
        <div className="text-primary">
          <span>{number}</span>
        </div>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { className: 'pe-7' },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <>
          <Badge
            bg={status === true ? 'success' : 'danger'}
            text={status === true ? 'dark' : 'light'}
          >
            {status === true ? 'ACTIVE' : 'INACTIVE'}
          </Badge>
        </>
      );
    }
  },
  {
    accessor: 'verified',
    Header: 'Verified',
    headerProps: { className: 'pe-7' },
    cellProps: {
      className: 'fw-semi-bold pe-1 text-dark'
    },
    Cell: rowData => {
      const { verified } = rowData.row.original;
      return (
        <div>
          <span>{verified ? 'Yes' : 'No'}</span>
        </div>
      );
    }
  },
  {
    accessor: 'flagged',
    Header: 'Flagged',
    headerProps: { className: 'pe-7' },
    cellProps: {
      className: 'fw-semi-bold pe-1 text-dark'
    },
    Cell: rowData => {
      const { flagged } = rowData.row.original;
      return (
        <div>
          <span>{flagged ? 'Yes' : 'No'}</span>
        </div>
      );
    }
  },
  {
    accessor: 'purchase_date',
    Header: 'Purchase Date',
    headerProps: { className: 'pe-7' },
    cellProps: {
      className: 'fw-semi-bold pe-1 text-dark'
    },
    Cell: rowData => {
      const { purchase_date } = rowData.row.original;

      const formatDate = date => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
      };

      return (
        <div>
          <span>{formatDate(purchase_date)}</span>
        </div>
      );
    }
  }
  // {
  //   accessor: 'none',
  //   Header: '',
  //   disableSortBy: true,
  //   cellProps: {
  //     className: 'text-end py-2'
  //   },
  //   Cell: () => {
  //     return (
  //       <CardDropdown drop="start">
  //         <div className="py-2">
  //           <Dropdown.Item href="#!">View</Dropdown.Item>
  //           <Dropdown.Divider as="div" />
  //           <Dropdown.Item href="#!" className="text-warning">
  //             Archive
  //           </Dropdown.Item>
  //           <Dropdown.Item href="#!" className="text-danger">
  //             Delete
  //           </Dropdown.Item>
  //         </div>
  //       </CardDropdown>
  //     );
  //   }
  // }
];

const NumbersTable = () => {
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    const fetchNumbers = async () => {
      const response = await viewOrgNumbers();
      setNumbers(response);
      console.log(numbers);
    };
    fetchNumbers();
  }, []);

  //   const formatDate = date => {
  //     const newDate = new Date(date);
  //     return newDate.toLocaleDateString();
  //   };

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={numbers}
        selection
        sortable
        pagination
        perPage={15}
        rowCount={numbers.length}
      >
        <Row className="mb-3 mt-3">
          <Col className="mb-3 mt-3">
            <Card className="mb-3">
              <Card.Header>
                <NumbersTablesHeader table />
              </Card.Header>
              <Card.Body>
                {numbers.length > 0 ? (
                  <div style={{ overflowY: 'auto', height: '75vh' }}>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="btn-reveal-trigger text-nowrap align-middle"
                      tableProps={{
                        size: 'sm',
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </div>
                ) : (
                  <Alert variant="info">
                    <p className="mb-0 text-center">
                      Numbers will appear here when they are available
                    </p>
                  </Alert>
                )}
              </Card.Body>
              <Card.Footer>
                <AdvanceTableFooter
                  table
                  rowCount={numbers.length}
                  rowInfo
                  navButtons
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </AdvanceTableWrapper>
    </>
  );
};

export default NumbersTable;
