import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AppContext, { CampaignContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import WizardModal from 'components/wizard/signup/WizardModal';
import Flex from 'components/common/Flex';
import DestinationsForm from './DestinationsForm';
// import CampaignSettings from './CampaignSettings';
import CampaignHours from './CampaignHours';
import Success from 'components/wizard/signup/Success';
import CsvTable from './UploadLeads';

// API helpers
import { createCampaign } from 'helpers/api/Campaign';
import { uploadCampaignLeads } from 'helpers/api/Campaign-Leads';

// FlowBuilder
import FlowBuilder from 'components/flowbuilder/Flow';

const NewCampaignLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useContext(AppContext);
  const { newCampaign, setNewCampaign, step, setStep, leads, validated } =
    useContext(CampaignContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);

  // Flow Builder
  const [campaignId, setCampaignId] = useState(null);

  const navItems = [
    {
      icon: 'globe',
      label: 'New Campaign'
    },
    {
      icon: 'clock',
      label: 'Campaign Hours'
    },

    {
      icon: 'diagram-project',
      label: 'Campaign Flow'
    },
    {
      icon: 'upload',
      label: 'Upload Leads'
    },
    {
      icon: 'thumbs-up',
      label: 'Success'
    }
  ];

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleNext = async data => {
    console.log('handleNext', data);
    updateContext(data);
    try {
      if (step === 1 && validated) {
        updateContext(data);
      } else if (step === 2 && validated) {
        updateContext(data);
      } else if (step === 3 && validated) {
        const response = await createCampaign(newCampaign);
        console.log('response', response.campaign_id);
        setCampaignId(response.campaign_id);
      } else if (step === 4 && campaignId) {
        const leadsData = leads.map(lead => {
          return {
            ...lead,
            tags: Array.isArray(lead.tags) ? lead.tags : [lead.tags || ''],
            source: lead.source || ''
          };
        });

        const response = await uploadCampaignLeads(campaignId, leadsData);
        console.log('response', response);
      }
    } catch (error) {
      console.log('error in handleNext', error);
    }

    setStep(step + 1);
  };

  // const handleNext = async data => {
  //   console.log('handleNext', data);
  //   updateContext(data);
  //   try {
  //     if (step === 3) {
  //       const response = await createCampaign(newCampaign);
  //       console.log('response', response.campaign_id);
  //       setCampaignId(response.campaign_id);
  //     } else if (step === 4 && campaignId) {
  //       const leadsData = leads.map(lead => {
  //         return {
  //           ...lead,
  //           tags: Array.isArray(lead.tags) ? lead.tags : [lead.tags || ''],
  //           source: lead.source || ''
  //         };
  //       });

  //       const response = await uploadCampaignLeads(campaignId, leadsData);
  //       console.log('response', response);
  //     }
  //   } catch (error) {
  //     console.log('error in handleNext', error);
  //   }

  //   setStep(step + 1);
  // };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 5) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(handleNext, onError)();
      }
    } else {
      toggle();
    }
  };

  useEffect(() => {
    setCampaignId('');
    console.log('campaignId', campaignId);
  }, []);

  const updatedCampaignHours = data => {
    setNewCampaign({ ...newCampaign, running_hours: data });
    console.log('data', newCampaign);
  };

  const updateContext = data => {
    setNewCampaign({ ...newCampaign, ...data });
    console.log('data', newCampaign);
  };

  const submitData = async data => {
    console.log('submitData', data);
  };

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(submitData)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <DestinationsForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {step === 2 && (
            <CampaignHours updatedCampaignHours={updatedCampaignHours} />
          )}
          {step === 3 && <FlowBuilder updateContext={updateContext} />}
          {step === 4 && <CsvTable />}
          {step === 5 && (
            <Success
              reset={() => {
                setStep(1);
                setNewCampaign({});
                reset();
              }}
            />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 5,
            ' d-flex': step < 5
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton>
          {step <= 3 ? (
            <IconButton
              variant="primary"
              className="ms-auto px-5"
              onClick={handleSubmit(handleNext, onError)}
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              disabled={!validated}
            >
              Next
            </IconButton>
          ) : (
            <IconButton
              variant="primary"
              className="ms-auto px-5"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              onClick={handleSubmit(handleNext, onError, submitData)}
            >
              Finish
            </IconButton>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 5 ? step > index : step > 4,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

NewCampaignLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default NewCampaignLayout;
