import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';

const CampaignLeadTblHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter,
  campaignId
}) => {
  return (
    <Row className="flex-between-center flex-column flex-sm-row">
      <Col sm="auto" className="d-flex align-items-center pe-0">
        <AdvanceTableSearchBox
          className="ms-2"
          placeholder="Search Leads"
          data-testid="search-leads"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Col>

      <Col sm="auto" className="">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="lead-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              className="mx-2"
              href={`/campaign/lead/upload/${campaignId}`}
            >
              <span className="ms-1">Upload</span>
            </IconButton>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="ms-1">Filter</span>
            </IconButton> */}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="ms-1">Export</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

CampaignLeadTblHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  campaignId: PropTypes.string
};

export default CampaignLeadTblHeader;
