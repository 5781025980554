import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Alert, Card, Dropdown } from 'react-bootstrap';
import WebhooksTableHeader from './WebhooksTableHeader';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { viewWebhooks } from 'helpers/api/Webhook';
import WebhookModal from './WebhookModal';
import PageHeader from 'components/common/PageHeader';
import CardDropdown from 'components/common/CardDropdown';

import { deleteWebhook } from 'helpers/api/Webhook';

const WebhooksInterface = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState(null);

  const fetchWebhooks = useCallback(async () => {
    try {
      const response = await viewWebhooks();
      if (response.status === 200) {
        setWebhooks(response.data);
      } else {
        console.error('Failed to fetch webhooks');
      }
    } catch (error) {
      console.error('Error fetching webhooks:', error);
    }
  }, []);

  useEffect(() => {
    fetchWebhooks();
  }, [fetchWebhooks]);

  const handleEdit = useCallback(id => {
    console.log('Edit:', id);
  }, []);

  const handleShowModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const selectWebhookById = useCallback(webhook => {
    setSelectedWebhook(webhook);
    console.log('Selected webhook:', webhook);
    console.log('Selected webhook:', selectedWebhook);
  }, []);

  const handleDelete = useCallback(
    async webhook_id => {
      try {
        const response = await deleteWebhook(webhook_id);
        if (response?.status === 200) {
          console.log(response);
          setWebhooks(prevWebhooks =>
            prevWebhooks.filter(webhook => webhook.webhook_id !== webhook_id)
          );
        } else {
          console.error('Failed to delete webhook');
        }
      } catch (error) {
        console.error('Error deleting webhook:', error);
      }
    },
    [setWebhooks]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        headerProps: { className: 'pe-1 sticky-column' },
        cellProps: {
          className: 'fw-semi-bold pe-1'
        },
        Cell: rowData => {
          const { name } = rowData.row.original;
          return <span className="text-dark fw-bold fs-0">{name}</span>;
        }
      },
      {
        Header: 'URL',
        accessor: 'url',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { url } = rowData.row.original;
          return <span className="text-dark fw-semi-bold">{url}</span>;
        }
      },

      {
        Header: 'Description',
        accessor: 'description',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { description } = rowData.row.original;
          return <span className="text-dark fw-semi-bold">{description}</span>;
        }
      },
      {
        Header: 'When',
        accessor: 'when',
        headerProps: { className: 'pe-7' },
        Cell: rowData => {
          const { when } = rowData.row.original;
          return <span className="text-dark fw-semi-bold">{when}</span>;
        }
      },
      {
        Header: 'Actions',
        accessor: 'webhook_id',
        Cell: rowData => {
          const { webhook_id } = rowData.row.original;
          return (
            <CardDropdown>
              <Dropdown.Item onClick={() => selectWebhookById(rowData.value)}>
                Edit
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleDelete(webhook_id)}>
                Delete
              </Dropdown.Item>
            </CardDropdown>
          );
        }
      }
    ],
    [handleEdit, selectWebhookById]
  );

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageHeader title="Webhooks" />
        </Col>
      </Row>
      <WebhookModal show={showModal} onHide={handleCloseModal} />

      <AdvanceTableWrapper
        columns={columns}
        data={webhooks}
        sortable
        filterable
        pagination
        selection
        perPage={15}
        rowCount={webhooks.length}
      >
        <Card>
          <Card.Header>
            <WebhooksTableHeader
              table
              handleShowModal={handleShowModal}
              refreshWebhooks={fetchWebhooks}
            />
          </Card.Header>
          <Card.Body className="p-0">
            {webhooks.length > 0 ? (
              <div style={{ overflowY: 'auto', height: '50vh' }}>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="btn-reveal-trigger text-nowrap align-middle"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0 overflow-auto'
                  }}
                />
              </div>
            ) : (
              <Alert variant="info" className="text-center">
                No webhooks found click on the Add Webhook button to create a
                new webhook
              </Alert>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              table
              rowCount={webhooks.length}
              pagination
              pagesCount={webhooks.length / 15}
              navButtons
              rowInfo
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default WebhooksInterface;
