import axiosWithCreds from '../../axios/axios';
export const createWebhook = async data => {
  try {
    const response = await axiosWithCreds.post(`/webhook`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const viewWebhooks = async () => {
  try {
    const response = await axiosWithCreds.get(`/webhook`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateWebhook = async webhook_id => {
  try {
    const response = await axiosWithCreds.put(`/webhook`, webhook_id, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteWebhook = async webhook_id => {
  try {
    const response = await axiosWithCreds.delete(`/webhook/${webhook_id}`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
