import axiosWithCreds from '../../axios/axios';

export const searchPurchasableNumbers = async ({
  page = null,
  limit = null,
  state = null,
  contains = null,
  starts_with = null,
  ends_with = null,
  rate_center = null,
  iso_country = null,
  number_type = null,
  order_by = null
} = {}) => {
  try {
    const params = {
      page,
      limit,
      state,
      contains,
      starts_with,
      ends_with,
      rate_center,
      iso_country,
      number_type,
      order_by
    };

    const response = await axiosWithCreds.get('numbers/search', { params });

    console.log('API response:', response);

    return response.data;
  } catch (error) {
    console.error('Error fetching purchasable numbers:', error);

    throw error;
  }
};
