import React, { useState, useEffect } from 'react';
import {
  Card,
  Dropdown,
  Badge,
  Row,
  Col,
  Spinner,
  Alert
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CampaignTableHeader from './CampaignTableHeader';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { archiveCampaign, deleteCampaign } from 'helpers/api/Campaign';

import { viewCampaigns } from 'helpers/api/Custom';

const CampaignTable = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      accessor: 'campaign_name',
      Header: 'Campaign Name',
      headerProps: { className: 'pe-1 sticky-column' },
      cellProps: {
        className: 'fw-semi-bold pe-1'
      },
      Cell: rowData => {
        const { campaign_id, campaign_name } = rowData.row.original;
        return (
          <>
            <Link
              // to={`/under-construction`}
              to={`/campaign/details/${campaign_id}`}
              className="text-dark fw-bold fs-0"
              alt-text="View Campaign"
            >{`${campaign_name}`}</Link>
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: { className: 'text-center pe-7' },
      cellProps: {
        className: 'fs-0',
        style: {
          width: '25px'
        }
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <div>
            <Badge
              pill
              bg={
                status === 'ACTIVE'
                  ? 'success'
                  : status === 'PENDING'
                  ? 'warning'
                  : 'danger'
              }
            >
              {status}
            </Badge>
          </div>
        );
      }
    },
    {
      accessor: 'timezone',
      Header: 'Timezone',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { timezone } = rowData.row.original;
        return (
          <div className="text-dark">
            <span>{timezone}</span>
          </div>
        );
      }
    },

    {
      accessor: 'total_active_leads',
      Header: 'Queued',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { total_pending_leads, total_leads } = rowData.row.original;
        return (
          <div className="text-dark">
            <span>{`${total_pending_leads} / ${total_leads}`}</span>
          </div>
        );
      }
    },

    {
      accessor: 'assigned_agents',
      Header: 'Agents',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { assigned_agents } = rowData.row.original;
        return (
          <div className="text-dark">
            <span>{assigned_agents}</span>
          </div>
        );
      }
    },
    {
      accessor: 'total_dnc_leads',
      Header: 'DNC Leads',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { total_dnc_leads } = rowData.row.original;
        return (
          <div className="text-dark">
            <span>{total_dnc_leads}</span>
          </div>
        );
      }
    },
    // {
    //   accessor: 'archived',
    //   Header: 'Archived',
    //   headerProps: { className: 'pe-7' },
    //   Cell: rowData => {
    //     const { archived } = rowData.row.original;
    //     return (
    //       <div className="text-dark">
    //         <span>{archived ? 'Yes' : 'No'}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      accessor: 'campaign_id',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end py-2'
      },
      Cell: rowData => {
        const [campaignArchived, setCampaignArchived] = useState(false);

        const { archived, campaign_id } = rowData.row.original;

        const toggleArchive = async () => {
          const response = await archiveCampaign(campaign_id, archived);
          if (response.status === 200) {
            setCampaignArchived(!campaignArchived);
          }
        };

        const handleDeleteCampaign = async () => {
          const response = await deleteCampaign(campaign_id);
          if (response.status === 200) {
            setCampaigns(
              campaigns.filter(campaign => campaign.campaign_id !== campaign_id)
            );
            toast.success('Campaign deleted successfully', {
              autoClose: 3000
            });
          } else {
            toast.error('Failed to delete campaign', {
              autoClose: 3000
            });
          }
        };

        return (
          <>
            <CardDropdown drop="start">
              <div className="py-2">
                <Dropdown.Item href={`/campaign/details/${campaign_id}`}>
                  View
                </Dropdown.Item>
                <Dropdown.Item href="#!">Edit</Dropdown.Item>
                <Dropdown.Item href="#!">Clone</Dropdown.Item>
                <Dropdown.Divider as="div" />
                <Dropdown.Item
                  href="#!"
                  className="text-warning"
                  onClick={toggleArchive}
                >
                  {archived ? 'Unarchive' : 'Archive'}
                </Dropdown.Item>
                <Dropdown.Item
                  href="#!"
                  className="text-danger"
                  onClick={handleDeleteCampaign}
                >
                  Delete
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </>
        );
      }
    }
  ];

  useEffect(() => {
    const fetchCampaigns = async () => {
      setIsLoading(true);
      const response = await viewCampaigns();
      setCampaigns(response);
      console.log(response);
      setIsLoading(false);
    };
    fetchCampaigns();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="text-center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <AdvanceTableWrapper
          columns={columns}
          data={campaigns}
          selection
          sortable
          pagination
          perPage={15}
        >
          <Row className="mb-3 mt-3">
            <Col className="mb-3 mt-3">
              <Card className="mb-3">
                <Card.Header>
                  <CampaignTableHeader
                    table
                    campaignId={campaigns.campaign_id}
                  />
                </Card.Header>
                <Card.Body>
                  {campaigns.length > 0 ? (
                    <div style={{ overflowY: 'auto', height: '50vh' }}>
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="btn-reveal-trigger text-nowrap align-middle"
                        tableProps={{
                          size: 'sm',
                          className: 'fs--1 mb-0 overflow-auto'
                        }}
                      />
                    </div>
                  ) : (
                    <Alert variant="info" className="text-center">
                      No campaigns found click on the Add Campaign button to add
                      a new campaign
                    </Alert>
                  )}
                </Card.Body>

                <Card.Footer>
                  <AdvanceTableFooter
                    table
                    rowCount={campaigns.length}
                    rowInfo
                    navButtons
                  />
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </AdvanceTableWrapper>
      )}
    </>
  );
};

export default CampaignTable;
