export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'home',
      to: '/dashboard',
      exact: true
    }
  ]
};
export const campaignRoutes = {
  label: 'Campaigns',
  labelDisable: true,
  children: [
    {
      name: 'Campaigns',
      active: true,
      icon: 'globe',
      to: '/campaigns/list',
      exact: true
    }
  ]
};
export const leadsRoutes = {
  label: 'Leads',
  labelDisable: true,
  children: [
    {
      name: 'Leads',
      active: true,
      icon: 'users',
      to: '/leads',
      exact: true
    }
  ]
};

export const reportRoutes = {
  label: 'Reports',
  labelDisable: true,
  children: [
    {
      name: 'Reports',
      active: true,
      icon: 'magnifying-glass-chart',
      to: '/reports/examples',
      exact: true
    }
  ]
};

export const numbersRoutes = {
  label: 'Numbers',
  labelDisable: true,
  children: [
    {
      name: 'Numbers',
      active: true,
      icon: 'hashtag',
      to: '/numbers',
      exact: true
    }
  ]
};

export const destinationsRoutes = {
  label: 'Destinations',
  labelDisable: true,
  children: [
    {
      name: 'Destinations',
      active: true,
      icon: 'map-pin',
      to: '/destinations',
      exact: true
    }
  ]
};

export const utilityRoutes = {
  label: 'Utilities',
  labelDisable: true,
  children: [
    {
      name: 'Toolbox',
      active: true,
      icon: 'tools',
      exact: true,
      children: [
        {
          name: 'Prompt Tool',
          active: true,
          icon: 'newspaper',
          to: '/tools/prompts',
          exact: true
        },
        {
          name: 'API Keys',
          active: true,
          icon: 'key',
          to: '/tools/api-keys',
          exact: true
        },
        {
          name: 'Webhooks',
          active: true,
          icon: 'link',
          to: 'tools/webhooks',
          exact: true
        }
      ]
    }
  ]
};

export default [
  dashboardRoutes,
  campaignRoutes,
  leadsRoutes,
  reportRoutes,
  numbersRoutes,
  destinationsRoutes,
  utilityRoutes
];
