import axiosWithCreds from '../../axios/axios';

export const viewPrompts = async () => {
  try {
    const response = await axiosWithCreds.get(`/prompt`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createPrompt = async data => {
  try {
    const response = await axiosWithCreds.post(`/prompt`, data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const applyOutboundPrompt = async (prompt_id, campaign_id) => {
  try {
    const response = await axiosWithCreds.put(
      `prompt/${prompt_id}/outbound/${campaign_id}`,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const applyInboundPrompt = async (prompt_id, campaign_id) => {
  try {
    const response = await axiosWithCreds.put(
      `prompt/${prompt_id}/inbound/${campaign_id}`,
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const approvePrompt = async prompt_id => {
  try {
    const response = await axiosWithCreds.post(`prompt/${prompt_id}/approve`, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const modifyPrompt = async (prompt_id, data) => {
  try {
    const response = await axiosWithCreds.patch(`prompt/${prompt_id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
