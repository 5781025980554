import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const PublicRoutes = ({ children }) => {
  const isLoggedIn = Cookies.get('isLoggedIn');
  const location = useLocation();

  if (isLoggedIn === 'true') {
    const from = location.state?.from?.pathname || '/dashboard';
    return <Navigate to={from} replace />;
  }

  return children;
};

PublicRoutes.propTypes = {
  children: PropTypes.node.isRequired
};

export default PublicRoutes;
