import React, { useState } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button } from 'react-bootstrap';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import PurchaseNumbersModal from './PurchaseNumbersModal';

const NumbersTablesHeader = ({
  selectedRowIds,
  globalFilter,
  setGlobalFilter
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Row className="flex-between-center flex-column flex-sm-row">
        <Col sm="auto" className="d-flex align-items-center pe-0">
          <AdvanceTableSearchBox
            className="ms-2"
            placeholder="Search Numbers"
            data-testid="search-numbers"
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </Col>

        <Col sm="auto" className="">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="numbers-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className="mx-2"
                onClick={handleShowModal}
              >
                <span className="ms-1">Purchase New</span>
              </IconButton>
            </div>
          )}
        </Col>
      </Row>
      <PurchaseNumbersModal show={showModal} onHide={handleCloseModal} />
    </>
  );
};

NumbersTablesHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
};

export default NumbersTablesHeader;
