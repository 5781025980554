import React from 'react';
import { Row, Col } from 'react-bootstrap';

//Components
import CampaignTable from 'components/tables/campaign/CampaignTables';
import PageHeader from 'components/common/PageHeader';
//comment
const CampaignList = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <PageHeader
            title="Campaign List"
            breadCrumbItems={[
              {
                path: '/campaign/list',
                active: true,
                label: 'Campaigns'
              }
            ]}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col>
          <CampaignTable />
        </Col>
      </Row>
    </>
  );
};

export default CampaignList;
