import React, { memo, useEffect, useState } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from '../nodes.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WebhookNode = ({ data }) => {
  WebhookNode.propTypes = {
    data: PropTypes.object.isRequired
  };

  const [errors, setErrors] = useState({ webhook: null });
  const [url, setUrl] = useState('');
  const [webhookData, setWebhoookData] = useState('');

  useEffect(() => {
    if (url === '' || url === null) {
      setErrors({ ...errors, webhook: 'URL is required.' });
    } else if (!validateUrl(url)) {
      console.log('url is invalid');
      setErrors({ ...errors, webhook: 'URL is invalid.' });
    } else {
      setErrors({ ...errors, webhook: null });
    }

    data.inputs.webhook.url = url;
  }, [url]);

  useEffect(() => {
    data.inputs.webhook.data = webhookData;
  }, [webhookData]);

  const validateUrl = url => {
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );

    return urlRegex.test(url);
  };

  return (
    <>
      <div className={cx(styles.nodeWrapper)}>
        <div className={cx(styles.nodeTitleBar)}>
          <div>
            <FontAwesomeIcon icon="bolt" />
          </div>
          <div className={cx(styles.nodeTitle)}>Webhook</div>
        </div>
        <div className={cx(styles.nodeContent)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              textAlign: 'left'
            }}
          >
            <div style={{ flex: 1 }}>
              <div>URL:</div>
            </div>
            <div style={{ width: '100%' }}>
              <div style={{ marginLeft: '4px' }}>
                <input
                  type="text"
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              textAlign: 'left',
              marginTop: '4px'
            }}
          >
            <div style={{ flex: 1 }}>
              <div>Data:</div>
            </div>
            <div style={{ width: '100%' }}>
              <div style={{ marginLeft: '4px' }}>
                <input
                  type="text"
                  value={webhookData}
                  onChange={e => setWebhoookData(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              display: errors.webhook === null ? 'visible' : 'hidden',
              flexDirection: 'row',
              width: '100%',
              textAlign: 'left',
              marginTop: '4px'
            }}
          >
            <div
              style={{
                flex: 1
              }}
            >
              <div>
                <span style={{ color: 'red' }}>
                  {errors.webhook ? errors.webhook : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="success-tooltip">{'Success'}</Tooltip>}
      >
        <Handle
          id="onSuccess"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '60px' }}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="failure-tooltip">{'Failure'}</Tooltip>}
      >
        <Handle
          id="onFailure"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '80px' }}
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="data-tooltip">{'Data'}</Tooltip>}
      >
        <Handle
          id="data"
          className={cx(styles.handleAction)}
          type="source"
          position={Position.Bottom}
          isConnectable={true}
          style={{ left: '100px' }}
        />
      </OverlayTrigger>
    </>
  );
};

export default memo(WebhookNode);
