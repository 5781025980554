import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Container,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import Tcpa from 'components/pages/miscellaneous/privacy-policy/Tcpa';
import Divider from 'components/common/Divider';
import axiosWithCreds from '../../axios/axios';
import logo from 'assets/img/vagents/whitelogo.png';

const leadSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  company: yup.string().required('Company is required'),
  title: yup.string(),
  preQualMethod: yup.string().required('PreQual Method is required'),
  internalPreQual: yup.string().required('Internal PreQual is required'),
  bpoCount: yup.number().required('BPO Count is required'),
  internalRepCount: yup.number().required('Internal Rep Count is required')
});

const DemoLeadForm = () => {
  const [leadData, setLeadData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    title: '',
    preQualMethod: '',
    internalPreQual: '',
    bpoCount: 0,
    internalRepCount: 0,
    demoOptIn: ''
  });
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState({});
  const [demoOptIn, setDemoOptIn] = useState('');

  const Link = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a href="#!">{children}</a>
    </OverlayTrigger>
  );

  const handleInputChange = e => {
    const { name, value } = e.target;
    setLeadData({ ...leadData, [name]: value });
  };

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setDemoOptIn(new Date().toLocaleString());
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(demoOptIn);
    try {
      await leadSchema.validate(leadData, { abortEarly: false });
      const response = await axiosWithCreds.post('demo/leads', {
        first_name: leadData.firstName,
        last_name: leadData.lastName,
        email: leadData.email,
        phone_number: leadData.phone,
        company: leadData.company,
        title: leadData.title,
        pre_qual_method: leadData.preQualMethod,
        internal_pre_qual: leadData.internalPreQual,
        bpo_count: leadData.bpoCount,
        internal_rep_count: leadData.internalRepCount,
        demoOptIn: demoOptIn
      });
      if (response.status === 200) {
        alert('Lead created successfully');
        setLeadData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',
          title: '',
          preQualMethod: '',
          internalPreQual: '',
          bpoCount: 0,
          internalRepCount: 0
        });
      }
      console.log(response);
    } catch (err) {
      const errors = {};
      err.inner.forEach(e => {
        errors[e.path] = e.message;
      });
      setError(errors);
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: '900px' }}>
      <Card>
        <Card.Header className="bg-primary text-dark">
          <div className="d-flex justify-content-between">
            <img src={logo} alt="logo" width="115.5rem" />
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="checkbox" className="mb-3 text-dark">
                  <Form.Check
                    type="checkbox"
                    label="I agree to the TCPA and Privacy Policy"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
                <Divider />
                {!isChecked && <Tcpa />}

                {isChecked && (
                  <>
                    <Row className=" d-flex justify-content-center">
                      <Col lg={12} className="mb-3">
                        <Form.Group controlId="firstName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={leadData.firstName}
                            onChange={handleInputChange}
                          />
                          {error.firstName && (
                            <Form.Text className="text-danger">
                              {error.firstName}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="lastName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={leadData.lastName}
                            onChange={handleInputChange}
                          />
                          {error.lastName && (
                            <Form.Text className="text-danger">
                              {error.lastName}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="company">
                          <Form.Label>Company</Form.Label>
                          <Form.Control
                            type="text"
                            name="company"
                            value={leadData.company}
                            onChange={handleInputChange}
                          />

                          {error.company && (
                            <Form.Text className="text-danger">
                              {error.company}
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={leadData.email}
                            onChange={handleInputChange}
                          />

                          {error.email && (
                            <Form.Text className="text-danger">
                              {error.email}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-center">
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="phone">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={leadData.phone}
                            onChange={handleInputChange}
                          />

                          {error.phone && (
                            <Form.Text className="text-danger">
                              {error.phone}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="title">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            value={leadData.title}
                            onChange={handleInputChange}
                          />

                          {error.title && (
                            <Form.Text className="text-danger">
                              {error.title}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-center">
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="preQualMethod">
                          <Form.Label className="d-flex justify-content-between">
                            Pre-Qualification Method{' '}
                          </Form.Label>
                          <Form.Select
                            aria-label="Pre-Qualification Method"
                            name="preQualMethod"
                            value={leadData.preQualMethod}
                            onChange={handleInputChange}
                          >
                            <option value="">Pre-Qualification Method</option>
                            <option value="BPO">
                              Clients are qualified before reaching my company
                            </option>
                            <option value="Internal">
                              Clients are qualified by my company
                            </option>
                          </Form.Select>

                          {error.preQualMethod && (
                            <Form.Text className="text-danger">
                              {error.preQualMethod}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="internalPreQual">
                          <Form.Label className="d-flex justify-content-between">
                            Internal PreQual {''}
                          </Form.Label>
                          <Form.Select
                            aria-label="Internal Pre-Qualification"
                            name="internalPreQual"
                            value={leadData.internalPreQual}
                            onChange={handleInputChange}
                          >
                            <option value="">Internal Pre-Qualification</option>
                            <option value="not_applicable">NO</option>
                            <option value="customer_service">Yes</option>
                          </Form.Select>

                          {error.internalPreQual && (
                            <Form.Text className="text-danger">
                              {error.internalPreQual}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-3 d-flex justify-content-center">
                      <Col md={6}>
                        <Form.Group controlId="bpoCount">
                          <Form.Label className="d-flex justify-content-between">
                            BPO Count{''}
                            <Link
                              id="bpoCount"
                              title="The amount of externally contracted call centre agents that you currently outsource"
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </Link>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="bpoCount"
                            value={leadData.bpoCount}
                            onChange={handleInputChange}
                          />

                          {error.bpoCount && (
                            <Form.Text className="text-danger">
                              {error.bpoCount}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="internalRepCount">
                          <Form.Label className="d-flex justify-content-between">
                            Internal Rep Count {''}
                            <Link
                              id="internalRepCount"
                              title="The amount of on-staff call centre agents you employ within your company"
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </Link>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="internalRepCount"
                            value={leadData.internalRepCount}
                            onChange={handleInputChange}
                          />

                          {error.internalRepCount && (
                            <Form.Text className="text-danger">
                              {error.internalRepCount}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6} className="mb-3">
                        <Button type="submit">Submit</Button>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

DemoLeadForm.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  id: PropTypes.string
};

export default DemoLeadForm;
