import React, { useState, useMemo } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { createDestination } from 'helpers/api/Destination';

dayjs.extend(utc);
dayjs.extend(timezone);

const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu'
];

const calculateTimeDifferences = localTimezone => {
  return timezones.reduce((acc, tz) => {
    const localOffset = dayjs().tz(localTimezone).utcOffset();
    const timezoneOffset = dayjs().tz(tz).utcOffset();
    const diffInHours = (timezoneOffset - localOffset) / 60;
    acc[tz] = diffInHours >= 0 ? `+${diffInHours}` : diffInHours;
    return acc;
  }, {});
};

const NewDestinationModal = ({ show, onHide, refreshDestinations }) => {
  const [destinationName, setDestinationName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [payout, setPayout] = useState('');
  const [notes, setNotes] = useState('');
  const [validated, setValidated] = useState(false);

  const localTimezone = dayjs.tz.guess();

  const timeDifferences = useMemo(
    () => calculateTimeDifferences(localTimezone),
    [localTimezone]
  );

  const [timezone, setTimezone] = useState(localTimezone);

  const handleTimezoneChange = e => {
    const selectedTimezone = e.target.value;
    setTimezone(selectedTimezone);
  };

  const resetValues = () => {
    setDestinationName('');
    setPhoneNumber('');
    setDescription('');
    setPayout('');
    setNotes('');
    setValidated(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const data = {
        name: destinationName,
        phone_number: phoneNumber,
        description,
        payout,
        notes,
        timezone
      };
      const response = await createDestination(data);
      if (response.status === 200) {
        toast.success('Destination added successfully', {
          autoClose: 3000
        });
        resetValues();
        onHide();
        refreshDestinations();
      } else {
        toast.error('Failed to add destination', {
          autoClose: 3000
        });
      }
    }
    setValidated(true);
  };

  return (
    <Modal show={show} onHide={onHide} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>New Destination</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Row className="mb-3">
            <Col>
              <Form.Group as={Col} controlId="destinationName">
                <Form.Label>
                  Destination Name
                  <span>*</span>
                </Form.Label>
                <Form.Control
                  required
                  name="destinationName"
                  type="text"
                  placeholder="Enter Destination Name"
                  value={destinationName}
                  onChange={e => setDestinationName(e.target.value)}
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Please enter a destination name
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label>
                Phone Number<span>*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="timezone">
              <Form.Label>Timezone</Form.Label>
              <Form.Select
                required
                name="timezone"
                aria-label="choose timezone"
                value={timezone}
                onChange={handleTimezoneChange}
              >
                {timezones.map((tz, idx) => (
                  <option key={idx} value={tz}>
                    {`(GMT${timeDifferences[tz]}) ${tz}`}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="payout">
              <Form.Label>
                Payout
                <span>*</span>
              </Form.Label>
              <InputGroup hasValidation className="mb-3">
                <InputGroup.Text id="payout">$</InputGroup.Text>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter Payout"
                  value={payout}
                  onChange={e => setPayout(e.target.value)}
                  pattern="^\$?\d+(\.(\d{2}))?$"
                  title="Please enter a valid dollar amount. Example: $100.00 or .45"
                />
              </InputGroup>

              <Form.Control.Feedback type="invalid" tooltip>
                Please enter a valid dollar amount. Example: $100.00 or .45
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="notes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Notes"
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className="mb-3">
          <Col></Col>
        </Row>

        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NewDestinationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  refreshDestinations: PropTypes.func.isRequired
};

export default NewDestinationModal;
