import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

const Associations = ({
  company_name,
  company_email,
  org_id,
  phone_number,
  address,
  address_line2,
  city,
  state,
  zip_code,
  seats
}) => {
  return (
    <Card>
      <Card.Header className="bg-light">
        <h5 className="mb-0">Business Info</h5>
      </Card.Header>
      <Card.Body className="fs--1">
        <Row className="g-3">
          <Col>{company_name}</Col>
          <Col>{company_email}</Col>
          <Col>{org_id}</Col>
        </Row>
        <Row className="g-3">
          <Col>{phone_number}</Col>
          <Col>{address}</Col>
          <Col>{address_line2}</Col>
          <Col>{city}</Col>
          <Col>{state}</Col>
          <Col>{zip_code}</Col>
          <Col>{seats}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Associations.propTypes = {
  company_name: PropTypes.string,
  company_email: PropTypes.string,
  org_id: PropTypes.string,
  phone_number: PropTypes.string,
  address: PropTypes.string,
  address_line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip_code: PropTypes.string,
  seats: PropTypes.number
};

export default Associations;
