import React, { memo } from 'react';

import { Handle, Position } from 'reactflow';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import cx from 'classnames';

import PropTypes from 'prop-types';

import styles from './nodes.module.css';

import UnpackDispoNode from './utility/UnpackDispoNode';

const UtilityNode = ({ data }) => {
  UtilityNode.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  };

  return (
    <>
      <Handle
        id="target"
        className={cx(styles.handleUtility)}
        type="target"
        position={Position.Top}
        isConnectable={true}
        style={{
          background: '#099a09'
        }}
      />
      {data.function === 'unpack_disposition' && (
        <UnpackDispoNode data={data} />
      )}
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="event-tooltip">{'Event'}</Tooltip>}
      >
        <Handle
          id="event"
          className={cx(styles.handleUtility)}
          type="source"
          position={Position.Right}
          isConnectable={true}
          style={{
            background: '#099a09'
          }}
        />
      </OverlayTrigger>
    </>
  );
};

export default memo(UtilityNode);
